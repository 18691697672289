import {
  Button,
  H3,
  H4,
  Input,
  Label,
  TwoSidedContentWrapper,
} from "components"
import { images } from "assets"
import {
  ActivateProfileCredentialsInterface,
  Form,
  useAppNotificationStore,
  useProfileStore,
} from "stores"
import { FormEvent, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export const ActivateProfile: React.FC<{}> = () => {
  const [form] = useState(new Form<ActivateProfileCredentialsInterface>())
  const { activateProfile } = useProfileStore()
  const { toast } = useAppNotificationStore()
  const { search } = useLocation()
  const navigate = useNavigate()

  const handleActivateProfile = async (e: FormEvent) => {
    e.preventDefault()
    // console.log("FORM 1", form.credentials)

    try {
      form.submitting(true)
      await activateProfile(form.credentials).then((resp) => {
        form.submitting(false)
        if (resp.isSuccessful === true) {
          toast.success(resp.message)
          navigate("/auth/login")
        } else {
          toast.error(resp.message)
        }
      })
    } catch (error) {
      form.submitting(false)
      console.log(error)
    }
  }

  useEffect(() => {
    form.setCredential("verificationCode", search.substring(1))
  }, [form, search])

  return (
    <TwoSidedContentWrapper displayImage={images.OnboardingImageDark}>
      <div className="flex flex-col gap-10">
        {/* Welcome */}
        <div className="flex flex-col gap-14">
          <div className="space-y-4 text-center">
            <H3 className="text-accentText font-semibold">Admission Portal</H3>
            <p className="text-accentGrey1">
              Welcome to LAUTECH Admission portal. Register to start an
              application and login if you already created an account.
            </p>
          </div>
          <div className="space-y-4">
            <H4 className="text-accentText font-semibold">
              Profile Activation
            </H4>
            <p className="text-accentGrey1">
              Please click the activation link sent to your email to activate
              your profile.
            </p>
          </div>
        </div>

        {/* Navigate to Login */}
        {/* <Button
          title="Go to Login"
          className="bg-pryLight1 text-pryBase font-medium"
        >
          Click here to Resend the activation code
        </Button> */}

        {/* Registration form */}
        <form
          onSubmit={handleActivateProfile}
          className="w-full flex flex-col gap-8 items-start"
        >
          <div className="w-full flex flex-col gap-6 items-start">
            <div className="w-full">
              <Label
                htmlFor="activation-code"
                aria-required={true}
              >
                Activation Code
              </Label>
              <Input
                id="activation-code"
                required
                readOnly={search.length > 0}
                autoComplete="given-name"
                className="w-full"
                placeholder="Enter your activation code"
                disabled={form.isSubmitting}
                defaultValue={search.substring(1, search.indexOf("&"))} // form.credentials.verificationCode
                onChange={(e) =>
                  form.setCredential("verificationCode", e.target.value)
                }
              />
            </div>
          </div>
          <div className="w-full">
            <Button
              type="submit"
              title="Activate Account"
              className="w-full bg-pryBase text-white"
              disabled={form.isSubmitting}
              submitting={form.isSubmitting ? "true" : "false"}
            >
              Activate Account
            </Button>
          </div>
        </form>
      </div>
    </TwoSidedContentWrapper>
  )
}
