import React from "react"
import { H4 } from "../typography/H4"
import { P2 } from "../typography/P2"
import { AnchorButton } from "../buttons/AnchorButton"
import { Link } from "react-router-dom"
import { logos } from "../../assets"
import { H6 } from "../typography/H6"
import { ContentWrapper } from "./ContentWrapper"

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: "" }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.message }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ContentWrapper>
          <div className="h-full w-full flex justify-center items-center">
            <div className="w-[466px] flex flex-col items-center gap-3">
              <Link to={"/"}>
                <img
                  src={logos.LautechLogo}
                  alt="Lautech logo"
                  className="h-12"
                />
              </Link>

              <div className="w-full bg-white rounded-2xl md:drop-shadow-custom pb-[42px] px-[42px] flex flex-col justify-center items-center gap-9">
                <div className="text-center text-error500">
                  <H4 className="font-semibold">Application error</H4>
                  <H6>
                    <code>{this.state.error} error</code>
                  </H6>
                </div>

                <div className="h-full w-full flex flex-col gap-6">
                  <P2 className="text-center text-neutral600">
                    Sorry, this error wasn't suppose to occur. Support Team have
                    been notified. Please contact support if the error persists.
                  </P2>
                </div>

                <div className="w-full flex items-center justify-between">
                  <AnchorButton
                    title="Return to Homepage"
                    href="/"
                    className="bg-pryLight1 text-pryBase"
                  >
                    <span>Return to homepage</span>
                  </AnchorButton>

                  <AnchorButton
                    target="__blank"
                    href="mailto:ebukaodini@gmail.com"
                    title="Contact support"
                    className="bg-primary text-white"
                  >
                    Contact support
                  </AnchorButton>
                </div>
              </div>
            </div>
          </div>
        </ContentWrapper>
      )
    }

    return this.props.children
  }
}
