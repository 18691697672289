import { Link } from "react-router-dom"
import { logos } from "../../assets"
import { H4 } from "../typography/H4"

export const SchoolBrand: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <Link
      to={"/"}
      className={`h-full flex gap-2 items-center ${className}`}
    >
      <img
        src={logos.LautechLogo}
        alt="Lautech logo"
        className="w-7 lg:w-10"
      />
      <H4 className={`text-3xl font-bold`}>LODLC</H4>
    </Link>
  )
}

export const SchoolBrandMobile: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <Link
      to={"/"}
      className={`h-full flex gap-2 items-center ${className}`}
    >
      <img
        src={logos.LautechLogo}
        alt="Lautech logo"
        className="w-7 lg:w-10"
      />
      {/* <H4 className={`text-3xl font-bold hidden lg:inline-block`}>LODLC</H4> */}
    </Link>
  )
}
