import React from "react"
import { ArrowDown2, HambergerMenu, Menu, Notification } from "iconsax-react"
import { H4 } from "../typography/H4"
import { IconButton } from "../buttons/IconButton"
import { images } from "../../assets"
import { P2 } from "../typography/P2"
import { useNavigate } from "react-router-dom"
import { useAppNotificationStore, useProfileStore } from "../../stores"
import { SchoolBrand, SchoolBrandMobile } from "../custom/SchoolBrand"

const UserProfileComponent: React.FC<{}> = () => {
  const { profile } = useProfileStore()
  return (
    <div className="flex gap-2.5 items-start cursor-pointer">
      <div className="w-[46px] h-full">
        <img
          src={profile?.avatar ?? images.DefaultProfilePicture}
          alt="User Profile"
          className="w-full"
        />
      </div>
      <div className="flex flex-col h-full my-auto">
        <P2 className="text-accentText font-semibold">
          {profile?.firstname ?? ""} {profile?.surname ?? ""}
        </P2>
        <P2 className="text-accentGrey1">
          {profile?.applicationNumber ?? "--"}
        </P2>
      </div>
      <ArrowDown2
        size={16}
        className="mt-1"
      />
    </div>
  )
}

export const TopNavigationBar: React.FC<{}> = () => {
  const navigate = useNavigate()
  const { dashboardContext } = useAppNotificationStore()
  return (
    <div className="top-0 left-0 right-0 sticky bg-white h-[78px] z-30 border-b border-accentGrey3">
      <div className="h-full w-full mx-auto max-w-[1116px] cont ainer px-5 flex justify-between items-center">
        <H4 className="font-semibold text-accentText">
          {dashboardContext ?? "Dashboard"}
        </H4>
        <div className="flex items-center gap-6">
          <IconButton
            title="Notifications"
            className="rounded-md shadow p-2"
            onClick={() => navigate("/dashboard/notifications")}
          >
            <Notification
              size={20}
              variant="Bulk"
              className="text-accentGrey1"
            />
          </IconButton>
          <UserProfileComponent />
        </div>
      </div>
    </div>
  )
}

export const MobileTopNavigationBar: React.FC<{}> = () => {
  const navigate = useNavigate()
  const { dashboardContext, toggleMenuOpen } = useAppNotificationStore()
  return (
    <div className="top-0 left-0 right-0 sticky bg-white h-[78px] z-30 border-b border-accentGrey3">
      <div className="h-full w-full mx-auto max-w-full container px-6 flex justify-between items-center">
        {/* <H4 className="font-semibold text-accentText">
          {dashboardContext ?? "Dashboard"}
        </H4> */}
        <SchoolBrandMobile className="text-white" />
        <div className="flex items-center gap-2">
          {/* <IconButton
            title="Notifications"
            className="rounded-md shadow p-2"
            > */}
          <Notification
            size={20}
            variant="Bold"
            className="text-accentGrey1 w-8"
            onClick={() => navigate("/dashboard/notifications")}
          />
          {/* </IconButton> */}
          <HambergerMenu
            variant="Linear"
            className="text-accentGrey1 w-8"
            onClick={() => toggleMenuOpen(true)}
          />
        </div>
      </div>
    </div>
  )
}
