import { FormEvent, useEffect, useState } from "react"
import {
  useAppNotificationStore,
  Form,
  useProfileStore,
  BioDataCredentialsInterface,
} from "stores"
import {
  Button,
  H4,
  Input,
  Label,
  P1,
  SelectInput,
  SelectOption,
} from "components"
import { formatDateInput, states } from "utils"

export const BioData: React.FC<{}> = () => {
  const { setDashboardContext } = useAppNotificationStore()
  const { profile, updateBioData } = useProfileStore()
  const [form] = useState(new Form<BioDataCredentialsInterface>())
  const { toast } = useAppNotificationStore()

  useEffect(() => {
    setDashboardContext("Bio-Data")
  }, [setDashboardContext])
  const [lga, setLga] = useState<string[]>([])

  useEffect(() => {
    form.setCredentials({
      lastName: profile?.surname,
      firstName: profile?.firstname,
      othername: profile?.othername,
      mobileNumber: profile?.mobileNo,
      address: profile?.address,
      state: profile?.state,
      lga: profile?.lga,
      gender: profile?.gender,
      dob: profile?.dob,
      maritalStatus: profile?.maritalStatus,
      // nationality: "Nigeria",
      ApplicantId: profile?.applicationNumber,
    })
    setLga(states.find((s) => s.state === profile?.state)?.lgas ?? [])
  }, [
    form,
    profile?.address,
    profile?.applicationNumber,
    profile?.dob,
    profile?.firstname,
    profile?.gender,
    profile?.lga,
    profile?.maritalStatus,
    profile?.mobileNo,
    profile?.othername,
    profile?.state,
    profile?.surname,
  ])

  const handleSubmitBioData = async (e: FormEvent) => {
    e.preventDefault()

    try {
      form.submitting(true)
      console.log(form.credentials)
      await updateBioData(form.credentials as BioDataCredentialsInterface).then(
        (resp) => {
          form.submitting(false)
          if (resp.isSuccessful === true) {
            toast.success(resp.message)
          } else {
            toast.error(resp.title)
            form.setErrors(resp.errors)
          }
        }
      )
    } catch (error: any) {
      form.submitting(false)
      console.log(error)
      toast.error(error?.message as string)
    }
  }

  console.log(form.credentials, profile)

  return (
    <div className="flex flex-col gap-12 items-center">
      <div className="flex flex-col items-center gap-6">
        <H4 className="font-semibold text-accentText">Bio-Data</H4>
        <P1 className="text-accentGrey1 text-center">
          Please fill in the details below to complete your Bio-Data
        </P1>
      </div>
      <form
        onSubmit={handleSubmitBioData}
        className="w-full flex flex-col gap-8"
      >
        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="space-y-[5px] w-full">
            <Label
              htmlFor="firstname"
              aria-required
            >
              First Name
            </Label>
            <Input
              id="firstname"
              placeholder="Enter your first name"
              required
              disabled={form.isSubmitting}
              defaultValue={form.credentials?.firstName}
              onChange={(e) => form.setCredential("firstName", e.target.value)}
            />
          </div>
          <div className="space-y-[5px] w-full">
            <Label
              htmlFor="lastname"
              aria-required
            >
              Last Name
            </Label>
            <Input
              id="lastname"
              placeholder="Enter your last name"
              required
              disabled={form.isSubmitting}
              defaultValue={form.credentials?.lastName}
              onChange={(e) => form.setCredential("lastName", e.target.value)}
            />
          </div>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="space-y-[5px] w-full">
            <Label
              htmlFor="othernames"
              aria-required
            >
              Other Name(s)
            </Label>
            <Input
              id="othernames"
              placeholder="Enter your other name(s)"
              required
              disabled={form.isSubmitting}
              defaultValue={form.credentials?.othername}
              onChange={(e) => form.setCredential("othername", e.target.value)}
            />
          </div>
          <div className="space-y-[5px] w-full">
            <Label
              htmlFor="marital-status"
              aria-required
            >
              Marital Status
            </Label>
            <SelectInput
              id="marital-status"
              placeholder="Select your marital status"
              required
              disabled={form.isSubmitting}
              defaultValue={form.credentials?.gender}
              onChange={(e) =>
                form.setCredential("maritalStatus", e.target.value)
              }
            >
              {["Single", "Married", "Separated", "Divorced"].map(
                (option, index) => (
                  <SelectOption
                    key={index}
                    value={option}
                  >
                    {option}
                  </SelectOption>
                )
              )}
            </SelectInput>
          </div>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="space-y-[5px] w-full">
            <Label
              htmlFor="email"
              aria-required
            >
              Email address
            </Label>
            <Input
              id="email"
              type={"email"}
              placeholder="Enter your email address"
              defaultValue={profile?.email}
              readOnly
              disabled={form.isSubmitting}
            />
          </div>
          <div className="space-y-[5px] w-full">
            <Label
              htmlFor="phone-number"
              aria-required
            >
              Phone Number
            </Label>
            <Input
              id="phone-number"
              type={"tel"}
              placeholder="Enter your phone number"
              required
              disabled={form.isSubmitting}
              defaultValue={form.credentials?.mobileNumber}
              onChange={(e) =>
                form.setCredential("mobileNumber", e.target.value)
              }
            />
          </div>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="space-y-[5px] w-full">
            <Label
              htmlFor="gender"
              aria-required
            >
              Select Gender
            </Label>
            <SelectInput
              id="gender"
              placeholder="Select your gender"
              required
              disabled={form.isSubmitting}
              defaultValue={form.credentials?.gender}
              onChange={(e) => form.setCredential("gender", e.target.value)}
            >
              {["Male", "Female"].map((option, index) => (
                <SelectOption
                  key={index}
                  value={option}
                >
                  {option}
                </SelectOption>
              ))}
            </SelectInput>
          </div>
          <div className="space-y-[5px] w-full">
            <Label
              htmlFor="dob"
              aria-required
            >
              Date of Birth
            </Label>
            <Input
              id="dob"
              type={"date"}
              placeholder="dd-mm-yy"
              required
              disabled={form.isSubmitting}
              value={formatDateInput(form.credentials?.dob)}
              onChange={(e) => form.setCredential("dob", e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-[5px] w-full">
          <Label
            htmlFor="address"
            aria-required
          >
            Address
          </Label>
          <Input
            id="address"
            placeholder="Enter your address"
            required
            disabled={form.isSubmitting}
            defaultValue={form.credentials?.address}
            onChange={(e) => form.setCredential("address", e.target.value)}
          />
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="space-y-[5px] w-full">
            <Label
              htmlFor=""
              aria-required
            >
              State of Origin
            </Label>
            <SelectInput
              id="state-of-origin"
              placeholder="Select your state of origin"
              required
              disabled={form.isSubmitting}
              onChange={(e) => {
                form.setCredential("state", e.target.value)
                setLga(
                  states.find((s) => s.state === e.target.value)?.lgas ?? []
                )
              }}
            >
              {states.map((option, index) => (
                <SelectOption
                  key={index}
                  value={option.state}
                >
                  {option.state}
                </SelectOption>
              ))}
            </SelectInput>
          </div>
          <div className="space-y-[5px] w-full">
            <Label
              htmlFor="local-government-area"
              aria-required
            >
              Local Government Area
            </Label>
            <SelectInput
              id="local-government-area"
              placeholder="Select your local government area"
              required
              disabled={form.isSubmitting}
              onChange={(e) => form.setCredential("lga", e.target.value)}
            >
              {lga.map((option, index) => (
                <SelectOption
                  key={index}
                  value={option}
                >
                  {option}
                </SelectOption>
              ))}
            </SelectInput>
          </div>
        </div>

        <div className="flex justify-end items-center mt-2">
          <Button
            type="submit"
            title="Next"
            color="primary"
            className="w-full lg:w-52"
            disabled={form.isSubmitting}
            submitting={form.isSubmitting ? "true" : "false"}
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  )
}
