import { P1, WelcomeNote } from ".."
import { Outlet } from "react-router-dom"
import { ArrowRight2 } from "iconsax-react"
import { stageLevel, useProfileStore } from "stores"

type IndicatorType = {
  index: number
  title: string
  active: boolean
  completed: boolean
}

export const RegistrationWrapper: React.FC<{}> = () => {
  const { profile } = useProfileStore()

  const indicators: IndicatorType[] = [
    {
      index: 1,
      title: "Bio-Data",
      active: stageLevel[profile?.currentStage!] === stageLevel.Biodata,
      completed: stageLevel[profile?.currentStage!] > stageLevel.Biodata,
    },
    {
      index: 2,
      title: "Next of Kin",
      active: stageLevel[profile?.currentStage!] === stageLevel.NextOfKin,
      completed: stageLevel[profile?.currentStage!] > stageLevel.NextOfKin,
    },
    {
      index: 3,
      title: "Program Choices",
      active:
        stageLevel[profile?.currentStage!] === stageLevel.ProgrammeApplication,
      completed:
        stageLevel[profile?.currentStage!] > stageLevel.ProgrammeApplication,
    },
    {
      index: 4,
      title: "Records",
      active: [
        stageLevel.EducationalRecord,
        stageLevel.OlevelResult,
        stageLevel.EmploymentRecord,
      ].includes(stageLevel[profile?.currentStage!]),
      completed:
        stageLevel[profile?.currentStage!] > stageLevel.EmploymentRecord,
    },
    {
      index: 5,
      title: "Completed Profile",
      active:
        stageLevel[profile?.currentStage!] === stageLevel.SubmitApplication,
      completed:
        stageLevel[profile?.currentStage!] > stageLevel.SubmitApplication,
    },
  ]

  return (
    <div className="flex flex-col gap-10">
      <WelcomeNote />
      <div className="w-full flex justify-between lg:justify-start items-center gap-3.5 mb-4">
        {indicators.map((indicator, index) => (
          <div
            key={index}
            className="flex gap-4 items-center"
          >
            <div
              className={`w-7 h-7 flex justify-center items-center rounded-full leading-none ${
                indicator.completed === true
                  ? "bg-pryBase text-white"
                  : indicator.active === true
                  ? "bg-white border border-pryBase lg:border-accentGrey2 text-pryBase lg:text-accentText"
                  : "bg-white border border-accentGrey2 text-accentText"
              }`}
            >
              {indicator.index}
            </div>
            <P1
              className={`hidden lg:block ${
                indicator.active
                  ? "font-semibold text-accentText"
                  : "font-medium text-accentGrey2"
              }`}
            >
              {indicator.title}
            </P1>
            {indicator.index < 5 && (
              <ArrowRight2
                size={16}
                className="text-accentGrey2"
              />
            )}
          </div>
        ))}
      </div>
      <div className="lg:px-20">
        <Outlet />
      </div>
    </div>
  )
}
