/**
 * An optimized input.
 */
export const Input: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = (
  props
) => {
  return (
    <input
      type={props.type ?? "text"}
      {...props}
      className={`rounded-lg w-full border border-accentGrey3 bg-white p-3 placeholder:text-accentGrey2 text-accentText focus:outline-0 ${props.className}`}
    />
  )
}
