import { Link } from "react-router-dom"
import { ContentWrapper, LinkButton } from "components"
import { logos } from "assets"

export const Welcome: React.FC<{}> = () => {
  return (
    <ContentWrapper>
      <div className="bg-white w-full h-full flex flex-col items-center justify-center gap-5">
        <Link to={"/"}>
          <img
            src={logos.LautechLogo}
            alt="Lautech logo"
            className="w-40"
          />
        </Link>

        <LinkButton
          to={"/auth/registration"}
          className="bg-pryBase text-white w-80"
          title="Get Started"
        >
          Get started
        </LinkButton>
      </div>
    </ContentWrapper>
  )
}
