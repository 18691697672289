import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { APP_ID } from "../utils/constants"
import { request } from "utils"
import { useAuthStore } from "./auth"

export interface ProfileCredentialsInterface {
  email: string
  lastName: string
  firstName: string
  othername: string
  mobileNumber: string
  password: string
}

export interface BioDataCredentialsInterface {
  lastName: string,
  firstName: string,
  othername: string,
  mobileNumber: string,
  address: string,
  nationality: string,
  state: string,
  maritalStatus: string,
  lga: string,
  gender: string,
  dob: string
}

export interface NextOfKinCredentialsInterface {
  lastName: string,
  firstName: string,
  othername: string,
  email: string,
  mobileNumber: string,
  gender: string,
  occupation: string,
  relationship: string,
  address: string,
  lga: string,
}

export interface ActivateProfileCredentialsInterface {
  verificationCode: string
}

export enum stages {
  ProfileInitiation = "ProfileInitiation",
  ProfileActivation = "ProfileActivation",
  PaymentInitiation = "PaymentInitiation",
  PaymentCompletion = "PaymentCompletion",
  Biodata = "Biodata",
  NextOfKin = "NextOfKin",
  ProgrammeApplication = "ProgrammeApplication",
  EducationalRecord = "EducationalRecord",
  OlevelResult = "OlevelResult",
  EmploymentRecord = "EmploymentRecord",
  PictureUpload = "PictureUpload",
  SubmitApplication = "SubmitApplication",
  ApplicationSumitted = "ApplicationSumitted"
}

export enum stageLevel {
  ProfileInitiation = 1,
  ProfileActivation = 2,
  PaymentInitiation = 3,
  PaymentCompletion = 4,
  Biodata = 5,
  NextOfKin = 6,
  ProgrammeApplication = 7,
  EducationalRecord = 8,
  OlevelResult = 9,
  EmploymentRecord = 10,
  PictureUpload = 11,
  SubmitApplication = 12,
  ApplicationSumitted = 13
}

interface ProfileInterface {
  profileId: any,
  applicationNumber: string,
  surname: string,
  firstname: string,
  othername: string,
  address: string,
  nationality: string,
  programmeId: any,
  state: string,
  lga: string,
  dob: string,
  mobileNo: string,
  email: string,
  gender: string,
  maritalStatus: string,
  nextName: string,
  nextEmail: string,
  nextPhoneNumber: string,
  nextAddress: string,
  nextGender: string,
  nextOccupation: string,
  formNumber: number,
  currentStage: stages,
  nextStage: stages,
  avatar: string,
  relationship: string,
  lastLogin: string
}

interface ProfileState {
  profile?: ProfileInterface
}

interface ProfileMethods {
  createProfile: (credential: ProfileCredentialsInterface) => Promise<any>
  updateBioData: (credential: BioDataCredentialsInterface) => Promise<any>
  processNextOfKin: (credential: NextOfKinCredentialsInterface) => Promise<any>
  activateProfile: (credential: ActivateProfileCredentialsInterface) => Promise<any>
}

export const useProfileStore = create<ProfileState & ProfileMethods>()(
  devtools(
    persist(
      (set, get) => ({
        profile: undefined,
        createProfile: async (credential: ProfileCredentialsInterface) => {
          return request.post({
            url: "/api/Profile/Create-Short-Profile",
            body: credential,
          })
        },
        updateBioData: async (credential) => {
          return request.patch({
            url: "/api/Profile/update-biodata",
            body: credential,
            headers: { ...useAuthStore.getState().getAuthHeader() }
          })
        },
        processNextOfKin: async (credential) => {
          return request.post({
            url: "/api/Profile/process-nextofkin",
            body: credential,
            headers: { ...useAuthStore.getState().getAuthHeader() }
          })
        },
        activateProfile: async (credential) => {
          return request.post({
            url: "/api/Profile/activate-profile",
            body: credential,
            headers: { ...useAuthStore.getState().getAuthHeader() }
          })
        }
      }),
      {
        name: `${APP_ID}.profile`,
      }
    )
  )
)
