import { create } from "zustand"
import { devtools } from "zustand/middleware"

interface FormState {
  isSubmitting: boolean
  credentials: any | object
  errors: any | object
  // form: Form
}

interface FormMethods {
  setCredentials: (credentials: FormState["credentials"]) => void
  setErrors: (errors: FormState["errors"]) => void
  setCredential: (field: string, value: any) => void
  setError: (field: string, value: any) => void
  submitting: (state: boolean) => void
}

export const useForm = create<FormState & FormMethods>()(
  devtools((set, get) => ({
    isSubmitting: false,
    credentials: {},
    errors: {},
    setCredentials: (credentials) => {
      set({
        credentials: credentials,
      })
    },
    setCredential: (field, value) => {
      set({
        credentials: { ...get().credentials, [field]: value },
        errors: { ...get().errors, [field]: undefined },
      })
    },
    setError: (field, message) => {
      set({
        errors: { ...get().errors, [field]: message },
      })
    },
    setErrors: (errors) => {
      set({
        errors: errors,
      })
    },
    submitting: (state) => {
      set({
        isSubmitting: state,
      })
    },
  }))
)

export class Form<T> {
  public isLoading: boolean = false
  public isSubmitting: boolean = false
  public credentials: T
  public errors: T

  constructor(credentials?: T, errors?: T) {
    this.credentials = credentials!
    this.errors = errors!
  }

  public setCredentials(credentials: any) {
    this.credentials = credentials
  }

  public setCredential(field: string, message: string) {
    this.credentials = { ...this.credentials, [field]: message }
    this.errors = { ...this.errors, [field]: undefined }
  }
  
  public setErrors(errors: any) {
    this.errors = errors
  }

  public setError(field: string, message: string) {
    this.errors = { ...this.errors, [field]: message }
  }

  public loading(state: boolean) {
    this.isLoading = state
  }
  
  public submitting(state: boolean) {
    this.isSubmitting = state
  }

}

