export const LoadingIndicator: React.FC<
  React.HTMLAttributes<HTMLDivElement>
> = (props) => {
  return (
    <div className="flex justify-center items-center">
      <div
        className={`inline-block w-5 h-5 border-2 border-r-transparent animate-spin rounded-full ${props.className}`}
        role="status"
      />
    </div>
  )
}

type props = {
  children: JSX.Element | JSX.Element[]
  message: string
  loading: boolean
}

export const Loader: React.FC<props> = ({ loading, children, message }) => {
  return (
    <>
      {loading === true ? (
        <div className="h-full w-full p-3 flex justify-center items-center gap-2">
          <LoadingIndicator className="text-pryBase" />
          <span>{message}</span>
        </div>
      ) : (
        children
      )}
    </>
  )
}
