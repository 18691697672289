import { Suspense } from "react"
import { Route, Routes } from "react-router-dom"
import { Welcome } from "./pages/public/Welcome"
import { Registration } from "./pages/auth/Registration"
import { Login } from "./pages/auth/Login"
import { DashboardWrapper, RegistrationWrapper } from "./components"
import { Dashboard } from "./pages/dashboard/Dashboard"
// import {} from "./components/layouts/RegistrationWrapper"
import { BioData } from "./pages/dashboard/registration/BioData"
import { NextOfKin } from "./pages/dashboard/registration/NextOfKin"
import { ProgramChoices } from "./pages/dashboard/registration/ProgramChoices"
import { Records } from "./pages/dashboard/registration/Records"
import { Complete } from "./pages/dashboard/registration/Complete"
import { Receipts } from "./pages/dashboard/Receipts"
import { AdmissionStatus } from "./pages/dashboard/AdmissionStatus"
import { ActivateProfile } from "pages/auth/ActivateProfile"
import { ForgotPassword } from "pages/auth/ForgotPassword"
import { InitiatePayment } from "pages/payments/InitiatePayment"
import { Payments } from "pages/payments/Payments"

function App() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/"
            element={<Welcome />}
          />
          <Route
            path="/auth/registration"
            element={<Registration />}
          />
          <Route
            path="/auth/login"
            element={<Login />}
          />
          <Route
            path="/auth/forgot-password"
            element={<ForgotPassword />}
          />
          <Route
            path="/auth/activate"
            element={<ActivateProfile />}
          />
          <Route
            path="/payments"
            element={<InitiatePayment />}
          />
          <Route
            path="/payments/all"
            element={<Payments />}
          />
          <Route
            path="/dashboard"
            element={<DashboardWrapper />}
          >
            <Route
              index
              element={<Dashboard />}
            />

            <Route
              path="registration"
              element={<RegistrationWrapper />}
            >
              <Route
                path="bio-data"
                element={<BioData />}
              />
              <Route
                path="next-of-kin"
                element={<NextOfKin />}
              />
              <Route
                path="program-choices"
                element={<ProgramChoices />}
              />
              <Route
                path="records"
                element={<Records />}
              />
              <Route
                path="completed-profile"
                element={<Complete />}
              />
            </Route>

            <Route
              path="receipts"
              element={<Receipts />}
            />
            <Route
              path="admission-status"
              element={<AdmissionStatus />}
            />
          </Route>
        </Routes>
      </Suspense>
    </>
  )
}

export default App
