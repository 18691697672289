import { H4 } from "../typography/H4"
import { P1 } from "../typography/P1"
import { illustrations } from "../../assets"
import { useState } from "react"
import { useProfileStore } from "stores"

export const WelcomeNote: React.FC<{}> = () => {
  const [registrationStatus, updateRegistrationStatus] = useState(false)
  const { profile } = useProfileStore()
  return (
    <>
      {/* TODO: update this to reflect when registration is completed */}
      {registrationStatus === false ? (
        <div className="w-full h-auto min-h-[151px] lg:min-h-[125px] lg:h-[125px] bg-notification-bg-light flex justify-between px-6 py-3 rounded-lg">
          <div className="flex flex-col gap-4 w-[542px]">
            <H4 className="text-accentText font-bold">
              Hello, {profile?.firstname ?? ""} {profile?.surname ?? ""}
            </H4>
            <P1 className="text-accentText">
              Welcome to LAUTECH Admission portal. Attend to the Sections below
              to complete your admission Registration.
            </P1>
          </div>
          <div className="hidden lg:block h-full">
            <img
              src={illustrations.NotificationLight}
              alt="Notification"
            />
          </div>
        </div>
      ) : (
        <div className="w-full h-auto min-h-[151px] lg:min-h-[125px] lg:h-[125px] bg-notification-bg-dark flex justify-between px-6 py-3 rounded-lg">
          <div className="flex flex-col gap-4 w-[793px]">
            <H4 className="text-white font-bold">Hello, Tiamiyu Mubarak</H4>
            <P1 className="text-white">
              Welcome to LAUTECH Admission portal. You have successfully
              completed your admission registration. Please keep checking your
              admission status to know when you have been offered admission.
            </P1>
          </div>
          <div className="hidden lg:block h-full">
            <img
              src={illustrations.NotificationDark}
              alt="Notification"
            />
          </div>
        </div>
      )}
    </>
  )
}
