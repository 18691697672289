import { LoadingIndicator } from "../custom/Loader"

/**
 * An optimized button.
 * Pass the `title` props, else `⚠` is returned
 */
export const Button: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    submitting?: "true" | "false"
    color?: "primary" | "secondary"
  }
> = (props) => {
  if (!props["title"]) return <span children={"⚠"} />
  return (
    <button
      aria-label={props.title}
      {...props}
      className={`rounded-lg hover:opacity-95 active:opacity-95 focus:opacity-95 disabled:opacity-50 py-3.5 px-4 lg:px-12 font-semibold focus:outline-none focus:shadow-none ${
        props.color! === "primary" ? "bg-pryBase text-white" : ""
      } ${props.color! === "secondary" ? "bg-pryLight1 text-pryBase" : ""} ${
        props.className
      }`}
    >
      <div className="w-full h-full flex gap-3 justify-center items-center">
        {props.type === "submit" && props?.submitting === "true" && (
          <LoadingIndicator className="text-white" />
        )}
        <span>{props.children}</span>
      </div>
    </button>
  )
}
