// logos
import LautechLogo from "./logos/logo.svg"

// images
import DefaultProfilePicture from "./images/profile-picture.svg"
import OnboardingUser from "./images/onboarding-user.svg"
import OnboardingImageDark from "./images/onboarding-image-dark.svg"
import Document from "./images/document.svg"

// illustrations
import AchievementBadge from "./illustrations/achievement-badge.svg"
import BioData from "./illustrations/bio-data.svg"
import Guardian from "./illustrations/guardian.svg"
import NoData from "./illustrations/no-data.svg"
import NotificationLight from "./illustrations/notification-light.svg"
import NotificationDark from "./illustrations/notification-dark.svg"
import ProgramChoices from "./illustrations/program-choices.svg"
import Records from "./illustrations/records.svg"
import Check from "./illustrations/check.svg"

export const logos = {
  LautechLogo,
}

export const images = {
  DefaultProfilePicture,
  OnboardingUser,
  OnboardingImageDark,
  Document,
}

export const illustrations = {
  AchievementBadge,
  BioData,
  Guardian,
  NoData,
  NotificationLight,
  NotificationDark,
  ProgramChoices,
  Records,
  Check,
}
