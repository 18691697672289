type Props = {
  errors: string[]
}

/**
 * Input errors.
 */
export const InputErrors: React.FC<Props> = ({ errors }) => {
  return (
    <>
      {errors && errors.length > 0 && (
        <div className="text-stateError font-medium text-xs mt-1">
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </div>
      )}
    </>
  )
}
