import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { APP_ID } from "../utils/constants"
import { request } from "utils"
import { useProfileStore } from "./profile"

export interface LoginInterface {
  username: string
  password: string
}

interface AuthState {
  isAuthenticated: boolean,
  accessToken?: string,
  refreshToken?: string,
  tokenType?: string,
  expiresIn?: number,
}

interface AuthMethods {
  getAuthHeader: () => object
  login: (credential: any) => Promise<any>
  changePassword: (credential: any) => Promise<any>
  resetPassword: (credential: any) => Promise<any>
  refreshTokenFn: (credential: any) => Promise<any>
  initiatePasswordReset: (credential: any) => Promise<any>
  signOut: () => void
}

export const useAuthStore = create<AuthState & AuthMethods>()(
  devtools(
    persist(
      (set, get) => ({
        isAuthenticated: false,
        accessToken: undefined,
        refreshToken: undefined,
        tokenType: undefined,
        expiresIn: undefined,
        getAuthHeader: () => {
          return {
            Authorization: `${get().tokenType} ${get().accessToken}`,
          }
        },
        login: async (credential: LoginInterface) => {
          return request.post({
            url: "/api/Authentication/Login",
            body: credential,
          }).then(resp => {
            if (resp.isSuccessful === true) {
              set({
                isAuthenticated: true,
                accessToken: resp.data.authDetails.accessToken,
                refreshToken: resp.data.authDetails.refreshToken,
                tokenType: resp.data.authDetails.tokenType,
                expiresIn: resp.data.authDetails.expiresIn,
              })
              useProfileStore.setState({
                profile: resp.data.profileDetail
              })
            }
            return resp
          })
        },
        changePassword: async (credential: LoginInterface) => {
          return request.post({
            url: "/api/Authentication/change-password",
            body: credential,
          }).then(resp => {
            console.log(resp)
            return resp
          })
        },
        resetPassword: async (credential: LoginInterface) => {
          return request.post({
            url: "/api/Authentication/reset-password",
            body: credential,
          }).then(resp => {
            console.log(resp)
            return resp
          })
        },
        refreshTokenFn: async (credential: LoginInterface) => {
          return request.post({
            url: "/api/Authentication/refresh-token",
            body: credential,
          }).then(resp => {
            console.log(resp)
            return resp
          })
        },
        initiatePasswordReset: async (credential: LoginInterface) => {
          return request.get({
            url: `/api/Authentication/initiate-password-reset/${credential.username}`,
          }).then(resp => {
            console.log(resp)
            return resp
          })
        },
        signOut: () => {
          set({
            isAuthenticated: false,
            accessToken: undefined,
            refreshToken: undefined,
            tokenType: undefined,
            expiresIn: undefined
          })
        },
      }),
      {
        name: `${APP_ID}.authentication`,
      }
    )
  )
)
