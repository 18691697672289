import React from "react"
import { NotificationBar } from "./NotificationBar"
import { SchoolBrand } from "../custom/SchoolBrand"

type props = {
  children: JSX.Element | JSX.Element[]
  displayImage: string
}

export const TwoSidedContentWrapper: React.FC<props> = ({
  children,
  displayImage,
}) => {
  return (
    <div className="h-screen w-screen bg-white">
      <NotificationBar />

      <div className="h-full mx-auto lg:container flex justify-between items-center">
        <div className="w-full h-full lg:w-1/2 lg:h-full overflow-y-auto">
          <div className="w-full lg:w-[542px] px-6 lg:px-0 container lg:pl-20 py-12 flex flex-col items-center lg:items-start justify-start">
            <SchoolBrand className="text-pryBase mb-20" />
            <div>{children}</div>
          </div>
        </div>
        <div className="hidden lg:w-1/2 lg:h-full lg:flex lg:justify-start">
          <img
            src={displayImage}
            alt="Lautech registration"
            className="w-full h-full object-cover object-left-top"
          />
        </div>
      </div>
    </div>
  )
}
