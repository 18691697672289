import { create } from "zustand"
import { devtools } from "zustand/middleware"

export interface Toast {
  id: string
  message: JSX.Element | string
  context: "success" | "error"
  autohide?: number | boolean
}

export interface Confirm {
  message: JSX.Element | string
  context: "success" | "error" | "warning"
  action: string
  onConfirm?: () => Promise<any>
  onCancel?: () => Promise<any>
}

interface AppNotificationState {
  menuOpen: boolean
  dashboardContext: string
  isLoading?: boolean
  toastList: Toast[]
  confirmProps?: {
    message: Confirm["message"]
    context: Confirm["context"]
    action: Confirm["action"]
    onConfirm?: Confirm["onConfirm"]
    onCancel?: Confirm["onCancel"]
  }
}

interface AppNotificationMethods {
  toggleMenuOpen: (state: boolean) => void
  setDashboardContext: (state: string) => void
  loading: (state: boolean) => void
  toastMachine: (
    message: Toast["message"],
    context: Toast["context"],
    autohide: Toast["autohide"]
  ) => void
  toast: {
    success: (message: Toast["message"], autohide?: Toast["autohide"]) => void
    error: (message: Toast["message"], autohide?: Toast["autohide"]) => void
    // warn: (message: Toast["message"], autohide?: Toast["autohide"]) => void
  }
  removeToast: (toast: Toast) => void
  toastId: () => string
  confirmMachine: (
    message: Confirm["message"],
    context: Confirm["context"],
    action: Confirm["action"],
    onConfirm?: Confirm["onConfirm"],
    onCancel?: Confirm["onCancel"]
  ) => void
  confirm: {
    success: (
      message: Confirm["message"],
      action: Confirm["action"],
      onConfirm?: Confirm["onConfirm"],
      onCancel?: Confirm["onCancel"]
    ) => void
    error: (
      message: Confirm["message"],
      action: Confirm["action"],
      onConfirm?: Confirm["onConfirm"],
      onCancel?: Confirm["onCancel"]
    ) => void
    warn: (
      message: Confirm["message"],
      action: Confirm["action"],
      onConfirm?: Confirm["onConfirm"],
      onCancel?: Confirm["onCancel"]
    ) => void
  }
  closeConfirm: () => void
}

export const useAppNotificationStore = create<
  AppNotificationState & AppNotificationMethods
>()(
  devtools((set, get) => ({
    menuOpen: false,
    dashboardContext: "",
    isLoading: false,
    toggleMenuOpen: (state) => {
      set({
        menuOpen: state,
      })
    },
    setDashboardContext: (context) => {
      set({ dashboardContext: context })
    },
    loading: (state) => {
      set({
        isLoading: state,
      })
    },
    toastList: [],
    toastId: () => {
      return `toast_${Math.floor(Math.random() * 99999)}`
    },
    removeToast(removedToast) {
      set({
        toastList: get().toastList.filter(
          (toast) => removedToast.id !== toast.id
        ),
      })
    },
    toast: {
      success: (message, autohide) =>
        get().toastMachine(message, "success", autohide!),
      error: (message, autohide) =>
        get().toastMachine(message, "error", autohide!),
      // warn: (message, autohide) =>
      //   get().toastMachine(message, "warning", autohide!),
    },
    toastMachine: (message, context, autohide = true) => {
      const toastId = get().toastId()
      const toast = {
        id: toastId,
        message,
        context
        // color:
        //   color === "success"
        //     ? ""
        //     : color === "danger"
        //     ? ""
        //     : "",
      }

      set({
        toastList: [toast, ...get().toastList],
      })

      if (typeof autohide == "boolean" && autohide === true) {
        const lifeTime = 10000
        setTimeout(() => {
          get().removeToast(toast)
        }, lifeTime)
      } else if (typeof autohide == "number") {
        setTimeout(() => {
          get().removeToast(toast)
        }, autohide * 1000)
      }
    },
    confirmProps: undefined,
    confirm: {
      success: (message, action, onConfirm?, onCancel?) =>
        get().confirmMachine(message, "success", action, onConfirm, onCancel),
      error: (message, action, onConfirm?, onCancel?) =>
        get().confirmMachine(message, "error", action, onConfirm, onCancel),
      warn: (message, action, onConfirm?, onCancel?) =>
        get().confirmMachine(message, "warning", action, onConfirm, onCancel),
    },
    confirmMachine: (message, context, action, onConfirm, onCancel) => {
      set({
        confirmProps: {
          context: context,
          message,
          action,
          onConfirm:
            onConfirm === undefined
              ? async () => get().closeConfirm()
              : onConfirm,
          onCancel: onCancel === undefined ? async () => {} : onCancel,
        },
      })
    },
    closeConfirm: () => {
      set({
        confirmProps: undefined,
      })
    },
  }))
)
