import React from "react"
import { useAppNotificationStore, useForm } from "../../stores"
import { Toast } from "../../stores/app-notification"
import { P3 } from "../typography/P3"
import { Check, CloseCircle, Warning2 } from "iconsax-react"
import { IconButton } from "../buttons/IconButton"
import { Button } from "../buttons/Button"
import { H4 } from "../typography/H4"
import { P1 } from "../typography/P1"

export const NotificationBar: React.FC<{}> = () => {
  const { toastList, removeToast, confirmProps, closeConfirm } =
    useAppNotificationStore()
  const { isSubmitting } = useForm()

  return (
    <>
      {toastList.length > 0 && (
        <div
          className={`absolute z-50 h-fit flex flex-col gap-3 md:min-w-[384px] md:w-96 min-w-[90%] top-0 right-0 ${
            toastList.length > 0 && "p-3 h-screen overflow-y-clip"
          }`}
        >
          {toastList.length > 0 &&
            toastList.map((toast: Toast) => (
              <div
                key={toast.id}
                className={`relative h-fit w-full flex items-start ${
                  toast.context === "success"
                    ? "text-stateSuccess bg-stateSuccessLight"
                    : "text-stateError bg-stateErrorLight"
                } p-3 gap-2 shadow-md`}
              >
                <IconButton
                  title="remove"
                  onClick={() => removeToast(toast)}
                  className={`absolute right-0 top-0 m-1 opacity-20 hover:opacity-100 hover:bg-opacity-70 ${
                    toast.context === "success"
                      ? "text-stateSuccess bg-stateSuccessLight"
                      : "text-stateError bg-stateErrorLight"
                  }`}
                >
                  <CloseCircle
                    variant="Linear"
                    className="w-5"
                  />
                </IconButton>

                <span className="mt-0.5">{toast.message}</span>
              </div>
            ))}
        </div>
      )}

      {confirmProps !== undefined && (
        <div className="absolute z-40 h-full flex justify-center top-0 bottom-0 left-0 right-0 bg-accentText/25">
          <div className="mt-[70%] md:mt-[40%] lg:mt-[10%] h-fit w-[90%] md:w-[50%] lg:w-[25%] md:min-w-[496px]">
            <div
              className={`h-full bg-white rounded-md px-6 py-4 shadow-lg flex flex-col justify-between gap-7`}
            >
              <div className="flex flex-col gap-2">
                <H4 className="font-semibold">{confirmProps.action}</H4>
                <P1 className="text-accentGrey1">{confirmProps.message}</P1>
              </div>

              <div className="w-full flex gap-2 justify-end">
                <Button
                  title="cancel"
                  color="secondary"
                  onClick={async () => {
                    await confirmProps.onCancel!()
                    closeConfirm()
                  }}
                  disabled={isSubmitting}
                  className="w-full"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  title={confirmProps.action}
                  onClick={async () => {
                    await confirmProps.onConfirm!()
                  }}
                  disabled={isSubmitting}
                  className="w-full"
                >
                  Yes, {confirmProps.action}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
