import { useEffect } from "react"
import { useAppNotificationStore } from "stores"
import { Button, H4, Label, P1, SelectInput, SelectOption } from "components"

export const ProgramChoices: React.FC<{}> = () => {
  const { setDashboardContext } = useAppNotificationStore()

  useEffect(() => {
    setDashboardContext("Program Choices")
  }, [setDashboardContext])

  return (
    <div className="flex flex-col gap-12 items-center">
      <div className="flex flex-col items-center gap-6">
        <H4 className="font-semibold text-accentText">Program Choices</H4>
        <P1 className="text-accentGrey1 text-center">
          Select your choice of program
        </P1>
      </div>
      <form className="w-full lg:w-[536px] flex flex-col gap-8">
        <div className="space-y-[5px] w-full">
          <Label aria-required>Your Desired Program</Label>
          <SelectInput
            placeholder="Select program"
            required
          >
            {/* TODO: fill up with programs */}
          </SelectInput>
        </div>

        <div className="space-y-[5px] w-full">
          <Label aria-required>Program Type</Label>
          <SelectInput required>
            {["Undergraduate program", "Postgraduate program"].map(
              (option, index) => (
                <SelectOption
                  key={index}
                  value={option.toLowerCase().replace(/\s/g, "-")}
                >
                  {option}
                </SelectOption>
              )
            )}
          </SelectInput>
        </div>

        <div className="space-y-[5px] w-full">
          <Label aria-required>Application Type</Label>
          <SelectInput required>
            {[
              { title: "UTME - O'Level 100l (4yrs)", value: "utme" },
              { title: "JUPEB, NCE, ND - 200l (3yrs)", value: "jupeb-nce-nd" },
              { title: "Nurses (RN / RM) - 200l (4yrs)", value: "nurses-rnrm" },
              {
                title: "Nurses (BSC, RN / RM) - 300l (3yrs)",
                value: "nurses-bsc-rnrm",
              },
              { title: "HND, BSC - 300l (2yrs)", value: "hnd-bsc" },
            ].map((option, index) => (
              <SelectOption
                key={index}
                value={option.value}
              >
                {option.title}
              </SelectOption>
            ))}
          </SelectInput>
        </div>

        <div className="flex gap-4 justify-end items-center mt-2">
          <Button
            type="submit"
            title="Previous"
            color="secondary"
            className="w-52"
          >
            Previous
          </Button>
          <Button
            type="submit"
            title="Next"
            color="primary"
            className="w-52"
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  )
}
