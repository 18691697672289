/**
 * An optimized checkbox input.
 */
export const CheckboxInput: React.FC<
  React.InputHTMLAttributes<HTMLInputElement>
> = (props) => {
  return (
    <input
      {...props}
      type={props.type ?? "checkbox"}
      className={`w-6 h-6 border-accentGrey2 checked:border-pryBase checked:text-white bg-white checked:bg-pryBase focus:outline-0 focus:outline-accentGrey2 hover:opacity-95 ${props.className}`}
    />
  )
}
