import React, { useState } from "react"
import { MobileTopNavigationBar, TopNavigationBar } from "./TopNavigationBar"

type props = {
  children: JSX.Element | JSX.Element[]
}

export const DashboardContent: React.FC<props> = ({ children }) => {
  // const [menuOpen, toggleMenuOpen] = useState(false)

  return (
    <div className="w-full lg:w-[calc(100%-260px)] lg:ml-[260px]">
      <div className="hidden lg:block">
        <TopNavigationBar />
      </div>
      <div className="block lg:hidden">
        <MobileTopNavigationBar />
      </div>

      <div className="w-full md:flex md:flex-col justify-start gap-10">
        <div className="w-full px-5 py-10 mx-auto max-w-[1116px] conta iner text-neutral700">
          {children}
        </div>
      </div>
    </div>
  )
}
