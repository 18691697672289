import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { APP_ID } from "../utils/constants"
import { request } from "utils"
import { useAuthStore } from "./auth"

export interface ProfileCredentialsInterface {
  email: string
  lastName: string
  firstName: string
  othername: string
  mobileNumber: string
  password: string
}

export interface InitiatePaymentCredentialsInterface {
  payType: "ApplicationFee"
}

export interface RequeryPaymentCredentialsInterface {
  transactionReference: string,
}

export interface ActivateProfileCredentialsInterface {
  verificationCode: string
}

interface PaymentMethods {
  initiatePayment: (credential: InitiatePaymentCredentialsInterface) => Promise<any>
  getPayments: () => Promise<any>
  verifyPayment: (credential: RequeryPaymentCredentialsInterface) => Promise<any>
}

export const usePaymentStore = create<PaymentMethods>()(
  devtools(
    persist(
      (set, get) => ({
        initiatePayment: async (credential) => {
          return request.post({
            url: "/api/Payment/initiate-payment",
            body: credential,
            headers: { ...useAuthStore.getState().getAuthHeader() }
          })
        },
        getPayments: async () => {
          return request.get({
            url: "/api/Payment/get-payments",
            headers: { ...useAuthStore.getState().getAuthHeader() }
          })
        },
        verifyPayment: async (credential) => {
          return request.post({
            url: "/api/Payment/requery-payment",
            body: credential,
            headers: { ...useAuthStore.getState().getAuthHeader() }
          })
        }
      }),
      {
        name: `${APP_ID}.payments`,
      }
    )
  )
)
