import { Eye, EyeSlash } from "iconsax-react"
import { useState } from "react"

/**
 * An optimized password input.
 */
export const PasswordInput: React.FC<
  React.InputHTMLAttributes<HTMLInputElement>
> = (props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  return (
    <div className="relative">
      <input
        {...props}
        type={showPassword === true ? "text" : "password"}
        className={`rounded-lg w-full border border-accentGrey3 bg-white p-3 placeholder:text-accentGrey2 text-accentText focus:outline-0  ${props.className}`}
      />
      <div className="absolute top-0 right-0 h-full flex items-center p-4 text-accentText cursor-pointer">
        {showPassword === true ? (
          <EyeSlash
            size={20}
            onClick={() => setShowPassword(false)}
            className="text-accentGrey2"
          />
        ) : (
          <Eye
            size={20}
            onClick={() => setShowPassword(true)}
            className="text-accentGrey2"
          />
        )}
      </div>
    </div>
  )
}
