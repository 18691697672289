import { useEffect, useState } from "react"
import { useAppNotificationStore } from "stores"
import { Button, H4, P1 } from "components"
import { illustrations } from "assets"

export const AdmissionStatus: React.FC<{}> = () => {
  const { setDashboardContext } = useAppNotificationStore()
  const [admission, setAdmission] = useState(false)

  useEffect(() => {
    setDashboardContext("Admission Status")
  }, [setDashboardContext])

  const details: { key: string; value: string }[] = [
    { key: "Full name", value: "Tiamiyu Mubarak" },
    { key: "Mode of Entry", value: "UTME" },
    { key: "Session", value: "2022/2023" },
    { key: "Department", value: "Computer Science" },
    { key: "Faculty", value: "Communication and Information Science" },
    { key: "Program Award", value: "B. Sc Computer Science " },
  ]

  return (
    <>
      <H4 className="font-semibold text-accentText mb-8 block lg:hidden">
        Admission Status
      </H4>
      {admission === true ? (
        <div className="flex flex-col gap-10 bg-pryExtraLight">
          <div className="px-5 lg:px-20 flex flex-col items-center gap-10 pt-8 pb-10">
            <div className="px-5 lg:px-0 flex flex-col items-center gap-6">
              <img
                src={illustrations.AchievementBadge}
                alt="Achievement badge"
              />
              <H4 className="font-semibold text-accentText text-center">
                Congratulations, Tiamiyu Mubarak.
              </H4>
              <p className="text-xl text-accentText text-center">
                You have been offered admission for a 5-year FIrst Degree
                Program. See Details Below and proceed to pay your acceptance
                fees.
              </p>
            </div>

            {/* Table */}
            <div className="px-4 lg:px-12 py-4 w-full bg-white flex flex-col gap-9">
              <P1 className="py-3 px-4 border-b-4 border-pryBase text-pryBase font-medium w-full lg:w-max">
                Admission Details
              </P1>
              <div className="w-full py-3 px-0 lg:px-4">
                {details.map((detail, index) => (
                  <div className="flex gap-2 lg:gap-10 py-1">
                    <P1 className="text-accentGrey1 py-4 font-medium min-w-[130px]">
                      {detail.key}
                    </P1>
                    <P1 className="text-accentText py-4 font-medium">
                      {detail.value}
                    </P1>
                  </div>
                ))}
              </div>

              <Button
                color="primary"
                title="Pay Acceptance Fees"
                className="w-full lg:w-max"
              >
                Pay Acceptance Fees
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-10 bg-[#F9FAFB]">
          <div className="px-5 lg:px-20 flex flex-col items-center gap-10 pt-8 pb-10">
            <div className="flex flex-col items-center gap-6">
              <img
                src={illustrations.NoData}
                alt="Achievement badge"
              />
              <H4 className="font-semibold text-accentText text-center">
                Hello, Tiamiyu Mubarak
              </H4>
              <p className="text-xl text-accentText text-center">
                Sorry, you have not been offered admission yet. Please, check
                back later.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
