import React from "react"
import { Link, matchRoutes, useLocation, useNavigate } from "react-router-dom"
import { P2, SchoolBrand, SchoolBrandMobile } from "../"
import {
  useAppNotificationStore,
  useAuthStore,
  useProfileStore,
} from "../../stores"
import {
  CloseCircle,
  FolderOpen,
  Home,
  LocationDiscover,
  LogoutCurve,
  Note,
  Notepad2,
  Receipt1,
  Teacher,
  UserOctagon,
} from "iconsax-react"
import { images } from "../../assets"

type ActionType = {
  title: string
  icon: JSX.Element
  route?: string
  patterns?: any[]
  action?: Function
}

const NavTab: React.FC<{ action: ActionType; index: number }> = ({
  action,
  index,
}) => {
  const { pathname } = useLocation()
  const { menuOpen, toggleMenuOpen } = useAppNotificationStore()

  return (
    <>
      {typeof action.patterns === "undefined" ? (
        <button
          title={action.title}
          className="w-full"
          onClick={() => {
            action.action!()
            if (menuOpen === true) toggleMenuOpen(false)
          }}
        >
          <div
            className={`w-full flex justify-start items-center gap-4 px-3 py-2 rounded-lg hover:bg-pryBase hover:text-white ${
              action.patterns !== undefined &&
              matchRoutes(action.patterns!, pathname) !== null
                ? "bg-pryBase text-white font-semibold"
                : "bg-transparent text-accentGrey2 lg:text-accentDarkIcon2 font-normal"
            }`}
          >
            <span>{action.icon}</span>
            <P2>{action.title}</P2>
          </div>
        </button>
      ) : (
        <Link
          to={action.route!}
          key={index}
          title={action.title}
          onClick={() => {
            if (menuOpen === true) toggleMenuOpen(false)
          }}
          className={`w-full flex justify-start items-center gap-4 px-3 py-2 rounded-lg hover:bg-pryBase hover:text-white ${
            action.patterns !== undefined &&
            matchRoutes(action.patterns!, pathname) !== null
              ? "bg-pryBase text-white font-semibold"
              : "bg-transparent text-accentGrey2 lg:text-accentDarkIcon2 font-normal"
          }`}
        >
          <span>{action.icon}</span>
          <P2>{action.title}</P2>
        </Link>
      )}
    </>
  )
}

const UserProfileComponent: React.FC<{}> = () => {
  const { toggleMenuOpen } = useAppNotificationStore()
  const { profile } = useProfileStore()
  return (
    <div className="w-full flex gap-2.5 justify-between items-start">
      <div className="w-full flex gap-2.5 cursor-pointer">
        <div className="w-[46px] h-full">
          <img
            src={profile?.avatar ?? images.DefaultProfilePicture}
            alt="User Profile"
            className="w-full"
          />
        </div>
        <div className="flex flex-col h-full my-auto">
          <P2 className="text-accentText font-semibold">
            {profile?.firstname ?? ""} {profile?.surname ?? ""}
          </P2>
          <P2 className="text-accentGrey1">
            {profile?.applicationNumber ?? "--"}
          </P2>
        </div>
      </div>
      <CloseCircle
        variant="Bulk"
        onClick={() => {
          toggleMenuOpen(false)
        }}
        className="w-7 text-accentGrey1 cursor-pointer mt-2"
      />
    </div>
  )
}

const mainMenu: ActionType[] = [
  {
    title: "Dashboard",
    icon: (
      <Home
        variant="Bulk"
        size={20}
      />
    ),
    route: "/dashboard",
    patterns: [{ path: "/dashboard" }],
  },
  {
    title: "Bio-Data",
    icon: (
      <Note
        variant="Bulk"
        size={20}
      />
    ),
    route: "/dashboard/registration/bio-data",
    patterns: [{ path: "/dashboard/registration/bio-data" }],
  },
  {
    title: "Next of Kin",
    icon: (
      <UserOctagon
        variant="Bulk"
        size={20}
      />
    ),
    route: "/dashboard/registration/next-of-kin",
    patterns: [{ path: "/dashboard/registration/next-of-kin" }],
  },
  {
    title: "Program Choices",
    icon: (
      <Notepad2
        variant="Bulk"
        size={20}
      />
    ),
    route: "/dashboard/registration/program-choices",
    patterns: [{ path: "/dashboard/registration/program-choices" }],
  },
  {
    title: "Records",
    icon: (
      <FolderOpen
        variant="Bulk"
        size={20}
      />
    ),
    route: "/dashboard/registration/records",
    patterns: [{ path: "/dashboard/registration/records" }],
  },
  {
    title: "Completed Profile",
    icon: (
      <LocationDiscover
        variant="Bulk"
        size={20}
      />
    ),
    route: "/dashboard/registration/completed-profile",
    patterns: [{ path: "/dashboard/registration/completed-profile" }],
  },
]

export const SideNavigationBar: React.FC<{}> = () => {
  const { signOut } = useAuthStore()
  const navigate = useNavigate()
  const { confirm, closeConfirm } = useAppNotificationStore()

  const otherMenu: ActionType[] = [
    {
      title: "Print Receipts",
      icon: (
        <Receipt1
          variant="Bulk"
          size={20}
        />
      ),
      route: "/dashboard/receipts",
      patterns: [{ path: "/dashboard/receipts" }],
    },
    {
      title: "View Admission Status",
      icon: (
        <Teacher
          variant="Bulk"
          size={20}
        />
      ),
      route: "/dashboard/admission-status",
      patterns: [{ path: "/dashboard/admission-status" }],
    },
    {
      title: "Logout",
      icon: (
        <LogoutCurve
          variant="Bulk"
          size={20}
        />
      ),
      // patterns: [],
      action: () => handleLogout(),
    },
  ]

  const handleLogout = () => {
    confirm.error(
      "Do you want to logout of your account?",
      "Logout",
      async () => {
        signOut()
        navigate("/auth/login")
        closeConfirm()
      }
    )
  }

  return (
    <div className="lg:w-[260px] fixed top-0 bottom-0 h-screen overflow-y-auto">
      <div className="h-full w-full bg-pryDark9 pb-4 px-6 flex flex-col justify-start gap-[30px]">
        {/* user component */}
        <div className="block lg:hidden">
          <UserProfileComponent />
        </div>

        {/* Brand */}
        <div className="hidden h-[86px] w-full lg:flex justify-start items-center">
          <SchoolBrand className="text-white" />
          {/* <SchoolBrandMobile className="text-white block lg:hidden" /> */}
        </div>

        <div className="h-full md:flex md:flex-col justify-start gap-6">
          <div className="w-full flex flex-col gap-1">
            <h6 className="text-xs text-accentDarkIcon2 ml-3">MAIN MENU</h6>
            <div className="h-auto w-full flex flex-col gap-2">
              {mainMenu.map((action, index) => (
                <NavTab
                  action={action}
                  key={index}
                  index={index}
                />
              ))}
            </div>
          </div>

          <hr className="border-pryDark10 w-full" />
          <div className="w-full flex flex-col gap-1">
            <h6 className="text-xs text-accentDarkIcon2 ml-3">OTHER</h6>
            <div className="h-auto overflow-y-auto w-full flex flex-col gap-2">
              {otherMenu.map((action, index) => (
                <NavTab
                  action={action}
                  key={index}
                  index={index}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const MobileSideNavigationBar: React.FC<{}> = () => {
  const { signOut } = useAuthStore()
  const navigate = useNavigate()
  const { confirm, closeConfirm } = useAppNotificationStore()

  const otherMenu: ActionType[] = [
    {
      title: "Print Receipts",
      icon: (
        <Receipt1
          variant="Bulk"
          size={20}
        />
      ),
      route: "/dashboard/receipts",
      patterns: [{ path: "/dashboard/receipts" }],
    },
    {
      title: "View Admission Status",
      icon: (
        <Teacher
          variant="Bulk"
          size={20}
        />
      ),
      route: "/dashboard/admission-status",
      patterns: [{ path: "/dashboard/admission-status" }],
    },
    {
      title: "Logout",
      icon: (
        <LogoutCurve
          variant="Bulk"
          size={20}
        />
      ),
      // patterns: [],
      action: () => handleLogout(),
    },
  ]

  const handleLogout = () => {
    confirm.error(
      "Do you want to logout of your account?",
      "Logout",
      async () => {
        signOut()
        navigate("/auth/login")
        closeConfirm()
      }
    )
  }

  return (
    <div className="bg-accentText/25 z-40 top-0 bottom-0 left-0 right-0 h-screen w-srceen fixed">
      <div className="w-[65%] [240px] right-0 fixed top-0 bottom-0 h-screen overflow-y-auto">
        <div className="h-full w-full bg-white pb-4 px-4 flex flex-col justify-start gap-[30px]">
          {/* user component */}
          <div className="lg:hidden h-[86px] w-full flex justify-start items-center">
            <UserProfileComponent />
          </div>

          <div className="h-full flex flex-col justify-start gap-6">
            <div className="w-full flex flex-col gap-1">
              <h6 className="text-xs text-accentGrey2 lg:text-accentDarkIcon2 ml-3">
                MAIN MENU
              </h6>
              <div className="h-auto w-full flex flex-col gap-2">
                {mainMenu.map((action, index) => (
                  <NavTab
                    action={action}
                    key={index}
                    index={index}
                  />
                ))}
              </div>
            </div>

            <hr className="border-accentGrey3 w-full" />

            <div className="w-full flex flex-col gap-1">
              <h6 className="text-xs text-accentGrey2 lg:text-accentDarkIcon2 ml-3">
                OTHER
              </h6>
              <div className="h-auto overflow-y-auto w-full flex flex-col gap-2">
                {otherMenu.map((action, index) => (
                  <NavTab
                    action={action}
                    key={index}
                    index={index}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
