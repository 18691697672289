import {
  Button,
  CheckboxInput,
  H3,
  H4,
  Input,
  Label,
  LinkButton,
  PasswordInput,
  TextLink,
  TwoSidedContentWrapper,
} from "components"
import { images } from "assets"
import { useNavigate } from "react-router-dom"
import { useAppNotificationStore, useAuthStore, useForm } from "stores"
import { FormEvent } from "react"
import { stages } from "stores/profile"

export const Login: React.FC<{}> = () => {
  const navigate = useNavigate()
  const { credentials, setCredential, submitting, isSubmitting } = useForm()
  const { toast } = useAppNotificationStore()
  const { login } = useAuthStore()

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault()

    try {
      submitting(true)
      await login(credentials).then((resp) => {
        submitting(false)
        if (resp.isSuccessful === true) {
          toast.success(resp.message)
          switch (resp.data.profileDetail.nextStage as stages) {
            case stages.ProfileInitiation:
            case stages.ProfileActivation:
              navigate("/auth/activate")
              break
            case stages.PaymentInitiation:
            case stages.PaymentCompletion:
              navigate("/payments")
              break
            case stages.Biodata:
              navigate("/dashboard/registration/bio-data")
              break
            case stages.NextOfKin:
              navigate("/dashboard/registration/next-of-kin")
              break
            case stages.ProgrammeApplication:
              navigate("/dashboard/registration/program-choices")
              break
            case stages.EducationalRecord:
            case stages.OlevelResult:
            case stages.EmploymentRecord:
              navigate("/dashboard/registration/records")
              break
            case stages.PictureUpload:
            case stages.SubmitApplication:
            case stages.ApplicationSumitted:
              navigate("/dashboard")
              break
            default:
              navigate("/dashboard")
              break
          }
        } else {
          toast.error(resp.message)
        }
      })
    } catch (error: any) {
      submitting(false)
      console.log(error)
      toast.error(error?.message as string)
    }
  }

  return (
    <TwoSidedContentWrapper displayImage={images.OnboardingImageDark}>
      <div className="flex flex-col gap-10">
        {/* Welcome */}
        <div className="flex flex-col gap-14">
          <div className="space-y-4 text-center">
            <H3 className="text-accentText font-semibold">Admission Portal</H3>
            <p className="text-accentGrey1">
              Welcome to LAUTECH Admission portal. Register to start an
              application and login if you already created an account.
            </p>
          </div>
          <div className="space-y-4">
            <H4 className="text-accentText font-semibold">Admission Login</H4>
            <p className="text-accentGrey1">
              Please enter your details to Login.
            </p>
          </div>
        </div>

        {/* Navigate to Registration */}
        <LinkButton
          to={"/auth/registration"}
          title="Go to Registration"
          className="bg-pryLight1 text-pryBase font-medium"
        >
          Click here to create an account
        </LinkButton>

        {/* Registration form */}
        <form
          onSubmit={handleLogin}
          className="w-full flex flex-col gap-8 items-start"
        >
          <div className="w-full flex flex-col gap-6 items-start">
            <div className="w-full">
              <Label
                htmlFor="username"
                aria-required={true}
              >
                Username
              </Label>
              <Input
                id="username"
                required
                autoComplete="off"
                className="w-full"
                placeholder="Enter your Username"
                disabled={isSubmitting}
                onChange={(e) => setCredential("username", e.target.value)}
              />
            </div>
            <div className="w-full">
              <Label
                htmlFor="password"
                aria-required={true}
              >
                Password
              </Label>
              <PasswordInput
                id="password"
                required
                autoComplete="current-password"
                className="w-full"
                placeholder="Enter your password"
                disabled={isSubmitting}
                onChange={(e) => setCredential("password", e.target.value)}
              />
            </div>
            <div className="w-full flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <CheckboxInput
                  id="remember-me"
                  disabled={isSubmitting}
                />
                <Label
                  htmlFor="remember-me"
                  className="mb-0 font-normal"
                >
                  Remember me
                </Label>
              </div>
              <div>
                <TextLink
                  title="Forgot password"
                  to={"/auth/forgot-password"}
                  className="text-pryBase text-sm"
                  aria-disabled={isSubmitting}
                >
                  Forgot Password?
                </TextLink>
              </div>
            </div>
          </div>
          <div className="w-full">
            <Button
              type="submit"
              // onClick={() => }
              title="Login"
              disabled={isSubmitting}
              submitting={isSubmitting ? "true" : "false"}
              className="w-full bg-pryBase text-white"
            >
              Login
            </Button>
          </div>
        </form>
      </div>
    </TwoSidedContentWrapper>
  )
}
