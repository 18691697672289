import {
  Button,
  H3,
  H4,
  Input,
  InputErrors,
  Label,
  LinkButton,
  PasswordInput,
  TwoSidedContentWrapper,
} from "components"
import { images } from "assets"
import { useAppNotificationStore, useForm, useProfileStore } from "stores"
import { FormEvent } from "react"

export const Registration: React.FC<{}> = () => {
  const {
    credentials,
    setCredential,
    errors,
    setError,
    submitting,
    isSubmitting,
  } = useForm()
  const { createProfile } = useProfileStore()
  const { toast } = useAppNotificationStore()

  const handleCreateProfile = async (e: FormEvent) => {
    e.preventDefault()

    try {
      if (credentials.password !== credentials.cpassword) {
        toast.error("Validation error.")
        setError("cpassword", [
          "The Password and Confirm Password must be the same!",
        ])
        return
      }

      submitting(true)
      await createProfile(credentials).then((resp) => {
        submitting(false)
        if (resp.isSuccessful === true) {
          toast.success(resp.message)
        } else {
          toast.error(resp.message)
        }
      })
    } catch (error) {
      submitting(false)
      console.log(error)
    }
  }

  return (
    <TwoSidedContentWrapper displayImage={images.OnboardingImageDark}>
      <div className="flex flex-col gap-10">
        {/* Welcome */}
        <div className="flex flex-col gap-14">
          <div className="space-y-4 text-center">
            <H3 className="text-accentText font-semibold">Admission Portal</H3>
            <p className="text-accentGrey1">
              Welcome to LAUTECH Admission portal. Register to start an
              application and login if you already created an account.
            </p>
          </div>
          <div className="space-y-4">
            <H4 className="text-accentText font-semibold">
              Admission Registration
            </H4>
            <p className="text-accentGrey1">
              Please fill in the details below to register for admission.
            </p>
          </div>
        </div>

        {/* Navigate to Login */}
        <LinkButton
          to={"/auth/login"}
          title="Go to Login"
          className="bg-pryLight1 text-pryBase font-medium"
        >
          Click here to Login to your account
        </LinkButton>

        {/* Registration form */}
        <form
          onSubmit={handleCreateProfile}
          className="w-full flex flex-col gap-8 items-start"
        >
          <div className="w-full flex flex-col gap-6 items-start">
            <div className="w-full">
              <Label
                htmlFor="firstname"
                aria-required={true}
              >
                First Name
              </Label>
              <Input
                id="firstname"
                required
                autoComplete="given-name"
                className="w-full"
                placeholder="Enter your firstname"
                disabled={isSubmitting}
                onChange={(e) => setCredential("firstName", e.target.value)}
              />
            </div>
            <div className="w-full">
              <Label
                htmlFor="surname"
                aria-required={true}
              >
                Surname
              </Label>
              <Input
                id="surname"
                required
                autoComplete="family-name"
                className="w-full"
                placeholder="Enter your surname"
                disabled={isSubmitting}
                onChange={(e) => setCredential("lastName", e.target.value)}
              />
            </div>
            <div className="w-full">
              <Label
                htmlFor="othername"
                aria-required={true}
              >
                Other Name(s)
              </Label>
              <Input
                id="othername"
                required
                autoComplete="additional-name"
                className="w-full"
                placeholder="Enter your other name(s)"
                disabled={isSubmitting}
                onChange={(e) => setCredential("othername", e.target.value)}
              />
            </div>
            {/* 
            <div className="w-full">
              <Label
                htmlFor="jamb-registration-number"
                aria-required={true}
              >
                Jamb Registration Number
              </Label>
              <Input
                id="jamb-registration-number"
                required
                autoComplete="off"
                className="w-full"
                placeholder="Enter your Jamb Registration Number"
              />
            </div> 
            */}
            <div className="w-full">
              <Label
                htmlFor="email"
                aria-required={true}
              >
                Email
              </Label>
              <Input
                id="email"
                type="email"
                required
                autoComplete="email"
                className="w-full"
                placeholder="Enter your Email address, e.g example@gmail.com"
                disabled={isSubmitting}
                onChange={(e) => setCredential("email", e.target.value)}
              />
            </div>
            <div className="w-full">
              <Label
                htmlFor="phone-number"
                aria-required={true}
              >
                Phone Number
              </Label>
              <Input
                id="phone-number"
                type="tel"
                required
                autoComplete="tel"
                className="w-full"
                placeholder="09021284572"
                disabled={isSubmitting}
                onChange={(e) => setCredential("mobileNumber", e.target.value)}
              />
            </div>
            <div className="w-full">
              <Label
                htmlFor="password"
                aria-required={true}
              >
                Password
              </Label>
              <PasswordInput
                id="password"
                required
                autoComplete="new-password"
                className="w-full"
                placeholder="Enter your password"
                disabled={isSubmitting}
                onChange={(e) => setCredential("password", e.target.value)}
              />
            </div>
            <div className="w-full">
              <Label
                htmlFor="confirm-password"
                aria-required={true}
              >
                Confirm Password
              </Label>
              <PasswordInput
                id="confirm-password"
                required
                autoComplete="new-password"
                className="w-full"
                placeholder="Confirm your password"
                disabled={isSubmitting}
                onChange={(e) => setCredential("cpassword", e.target.value)}
              />
              {/* {errors.cpassword &&  */}
              <InputErrors errors={errors.cpassword} />
              {/* } */}
            </div>
          </div>
          <div className="w-full">
            <Button
              type="submit"
              title="Register"
              disabled={isSubmitting}
              submitting={isSubmitting ? "true" : "false"}
              className="w-full bg-pryBase text-white"
            >
              Register
            </Button>
          </div>
        </form>
      </div>
    </TwoSidedContentWrapper>
  )
}
