import { useEffect, useState } from "react"
import { useAppNotificationStore } from "stores"
import { Button, H4, H6, P1, P2 } from "components"
import { InfoCircle, TickCircle } from "iconsax-react"
import { images } from "assets"

type UploadedResultType = {
  type: string
  file: File
}

export const Complete: React.FC<{}> = () => {
  const { setDashboardContext } = useAppNotificationStore()
  const [profileCompleted, updateProfileCompleted] = useState<boolean>(true)
  const [uploadedResults, updateUploadedResults] = useState<
    UploadedResultType[]
  >([
    { type: "WAEC Result", file: new File([], "") },
    { type: "Birth Certificate", file: new File([], "") },
    { type: "WAEC Result", file: new File([], "") },
    { type: "Birth Certificate", file: new File([], "") },
  ])

  useEffect(() => {
    setDashboardContext("Bio-Data")
  }, [setDashboardContext])

  return (
    <div className="flex flex-col gap-12 items-center">
      <div className="flex flex-col items-center gap-6">
        <H4 className="font-semibold text-accentText">
          Your Completed Profile
        </H4>
        <P1 className="text-accentGrey1 text-center">
          This is your completed details. Kindly check them over and make sure
          they're correct.
        </P1>
      </div>
      <div className="w-full flex flex-col gap-8">
        <div className="w-full flex flex-col gap-4">
          <div className="flex gap-2.5">
            {/* TODO: update this to reflect when registration is completed */}
            {profileCompleted === true ? (
              <>
                <TickCircle
                  variant="Bulk"
                  size={24}
                  className="text-stateSuccess/80"
                />
                <span className="text-stateSuccess font-medium">
                  You have completed your application process.
                </span>
              </>
            ) : (
              <>
                <InfoCircle
                  variant="Bulk"
                  size={24}
                  className="text-stateError/80"
                />
                <span className="text-stateError font-medium">
                  You have not completed your application process.
                </span>
              </>
            )}
          </div>
          <div className="w-full p-4 bg-pryExtraLight">
            <H6 className="text-pryBase font-semibold">Bio-Data</H6>
          </div>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">Full Name</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              Tiamiyu Mubarak Olaide
            </div>
          </div>
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">
              Jamb Registration Number
            </P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              56341576RD
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">Email address</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              almubaarak.dev@gmail.com
            </div>
          </div>
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">Phone Number</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              09021284572
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">Gender</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              Male
            </div>
          </div>
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">Date of Birth</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              12-05-1994
            </div>
          </div>
        </div>

        <div className="space-y-[5px] w-full">
          <P2 className="text-accentGrey1 font-medium">Address</P2>
          <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
            No 10, Olorunshogo Street, beside Jakande Plaza, Ikeja, Lagos State.
          </div>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">State of Origin</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              Kogi State
            </div>
          </div>
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">
              Local Government Area
            </P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              Ajaokuta
            </div>
          </div>
        </div>

        <div className="w-full p-4 bg-pryExtraLight">
          <H6 className="text-pryBase font-semibold">Guardian Details</H6>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">Full Name</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              Mr Tiamiyu Adebayo
            </div>
          </div>
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">Occupation</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              Lecturer
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">Email address</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              almubaarak.dev@gmail.com
            </div>
          </div>
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">Phone Number</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              09021284572
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">Gender</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              Male
            </div>
          </div>
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">Relationship</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              Father
            </div>
          </div>
        </div>

        <div className="space-y-[5px] w-full">
          <P2 className="text-accentGrey1 font-medium">Address</P2>
          <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
            No 10, Olorunshogo Street, beside Jakande Plaza, Ikeja, Lagos State.
          </div>
        </div>

        <div className="w-full p-4 bg-pryExtraLight">
          <H6 className="text-pryBase font-semibold">Next of Kin Details</H6>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">Full Name</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              Mr. Oladele Ibrahim
            </div>
          </div>
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">Occupation</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              Student
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">Email address</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              olaibrahim2@gmail.com
            </div>
          </div>
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">Phone Number</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              09021284572
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">Gender</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              Male
            </div>
          </div>
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">Relationship</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              Cousin
            </div>
          </div>
        </div>

        <div className="space-y-[5px] w-full">
          <P2 className="text-accentGrey1 font-medium">Address</P2>
          <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
            No 10, Olorunshogo Street, beside Jakande Plaza, Ikeja, Lagos State.
          </div>
        </div>

        <div className="w-full p-4 bg-pryExtraLight">
          <H6 className="text-pryBase font-semibold">Program Choices</H6>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">Selected Program</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              Computer Science
            </div>
          </div>
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">Program Type</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              Undergraduate Program
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="space-y-[5px] w-full">
            <P2 className="text-accentGrey1 font-medium">Application Type</P2>
            <div className="w-full border-b-2 border-accentGrey3 bg-white p-3 text-accentText font-medium">
              UTME
            </div>
          </div>
          <div className="space-y-[5px] w-full" />
        </div>

        <div className="w-full p-4 bg-pryExtraLight">
          <H6 className="text-pryBase font-semibold">Uploaded Records</H6>
        </div>

        <div className="flex flex-wrap justify-between gap-4">
          {uploadedResults.length === 0 ? (
            <P1 className="text-accentGrey1">No uploaded results yet.</P1>
          ) : (
            uploadedResults.map((result, index) => (
              <div
                key={index}
                className="w-full lg:w-[32%] flex justify-between items-center border border-accentGrey3 p-4 rounded-lg"
              >
                <div className="flex items-center gap-10">
                  <img
                    src={images.Document}
                    alt={result.type}
                    className="rounded-[4px] w-10 h-10"
                  />
                  <P1 className="text-accentText font-semibold ">
                    {result.type}
                  </P1>
                </div>
              </div>
            ))
          )}
        </div>

        <div className="flex justify-end items-center mt-2">
          <Button
            type="button"
            title="Print"
            color="primary"
            // TODO: update this to reflect when registration is completed
            disabled={true}
            className="w-full lg:w-max"
          >
            Print Completed Application Form
          </Button>
        </div>
      </div>
    </div>
  )
}
