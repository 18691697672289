import { useEffect, useState } from "react"
import { useAppNotificationStore } from "stores"
import { H4, H6, P1, TextButton, WelcomeNote } from "components"
import { ArrowLeft, ArrowRight, Printer } from "iconsax-react"
import { formatMoney } from "utils"

type ReceiptType = {
  id?: number
  date: string // Date
  amount: number
  description: string
  transactionNumber: string
}

const TableRow: React.FC<{
  index: number
  receipt: ReceiptType
}> = ({ index, receipt }) => {
  return (
    <tr
      key={index}
      className={`hover:bg-pryLight1`}
    >
      <td
        align="center"
        // className="align-middle py-[22px] px-2.5 flex items-center gap-2"
        className="align-middle text-base font-normal text-accentText py-[22px] px-2.5"
      >
        <span className={`text-base font-normal text-accentText`}>
          {receipt.id}
        </span>
      </td>
      <td
        align="center"
        className="align-middle text-base font-normal text-accentText py-[22px] px-2.5"
      >
        <span className={`text-base font-normal text-accentText`}>
          {receipt.date}
        </span>
      </td>
      <td
        align="center"
        className="align-middle text-base font-normal text-accentText py-[22px] px-2.5"
      >
        <span className={`text-base font-normal text-accentText`}>
          #{formatMoney(receipt.amount, 0)}
        </span>
      </td>
      <td
        align="center"
        className="align-middle text-base font-normal text-accentText py-[22px] px-2.5"
      >
        <span className={`text-base font-normal text-accentText`}>
          {receipt.description}
        </span>
      </td>
      <td
        align="center"
        className="align-middle text-base font-normal text-accentText py-[22px] px-2.5"
      >
        <span className={`text-base font-normal text-accentText`}>
          {receipt.transactionNumber}
        </span>
      </td>
      <td
        align="center"
        className="align-middle text-base font-normal text-accentText py-[22px] px-2.5"
      >
        <TextButton
          type="button"
          title="Print"
          className="flex gap-1 items-center text-pryBase"
        >
          <Printer size={18} />
          <span className="font-medium underline">Print</span>
        </TextButton>
      </td>
    </tr>
  )
}

export const Receipts: React.FC<{}> = () => {
  const { setDashboardContext } = useAppNotificationStore()

  useEffect(() => {
    setDashboardContext("Receipts")
  }, [setDashboardContext])

  let countBegin = 1
  const [receipts, updateReceipts] = useState<ReceiptType[]>(
    new Array<ReceiptType[]>(50)
      .fill([
        {
          date: "06-01-2023",
          amount: 25_000,
          description: "Acceptance fee",
          transactionNumber: "215634678289",
        },
      ])
      .flat()
      .map((receipt) => ({ id: countBegin++, ...receipt }))
  )
  const [pagination, setPagination] = useState({
    current: 1,
    total: Math.ceil(receipts?.length! / 10),
    limit: 10,
  })
  const [slicedReceipts, updateSlicedReceipts] = useState<ReceiptType[]>([])

  useEffect(() => {
    return updateSlicedReceipts(
      receipts.slice(
        (pagination.current - 1) * pagination.limit,
        pagination.limit * pagination.current
      )
    )
  }, [receipts, pagination])

  return (
    <div className="flex flex-col gap-10">
      <WelcomeNote />
      <div className="lg:px-20 flex flex-col gap-7">
        <div className="flex flex-col gap-12 items-center">
          <div className="flex flex-col items-center gap-6">
            <H4 className="font-semibold text-accentText">Payment Receipts</H4>
            <P1 className="text-accentGrey1 text-center">
              All information about your payment history
            </P1>
          </div>
          <div className="w-full px-6 py-4 bg-pryBase">
            <H6 className="text-white font-semibold">
              List of Payment Receipts
            </H6>
          </div>
        </div>

        {/* Table */}
        <div className="w-full overflow-x-auto">
          <table className="h-auto w-full table table-auto">
            <thead className="table-header-group bg-pryLight1 text-accentText">
              <tr>
                <th
                  align="center"
                  className="align-middle px-2.5 font-medium text-base py-4 min-w-[60px]"
                >
                  S/N
                </th>
                <th
                  align="center"
                  className="align-middle px-2.5 font-medium text-base py-4 min-w-[112px]"
                >
                  Date
                </th>
                <th
                  align="center"
                  className="align-middle px-2.5 font-medium text-base py-4 min-w-[144px]"
                >
                  Amount
                </th>
                <th
                  align="center"
                  className="align-middle px-2.5 font-medium text-base py-4 min-w-[232px]"
                >
                  Payment&nbsp;Description
                </th>
                <th
                  align="center"
                  className="align-middle px-2.5 font-medium text-base py-4 min-w-[168px]"
                >
                  Transaction&nbsp;No
                </th>
                <th
                  align="center"
                  className="align-middle px-2.5 font-medium text-base py-4 min-w-[96px]"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {slicedReceipts.map((receipt, index) => (
                <>
                  {index === 0 && (
                    <tr>
                      <td
                        colSpan={6}
                        className="h-2.5"
                      ></td>
                    </tr>
                  )}
                  <TableRow
                    key={index}
                    index={index}
                    receipt={receipt}
                  />
                  {slicedReceipts.length - 1 > index && (
                    <tr>
                      <td colSpan={6}>
                        <hr className="mx-auto my-1 border-accentGrey3 w-full" />
                      </td>
                    </tr>
                  )}
                  {slicedReceipts.length - 1 === index && (
                    <tr>
                      <td
                        colSpan={6}
                        className="h-5"
                      ></td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="flex items-center gap-10 justify-between lg:justify-end">
          {/* Previous */}
          <TextButton
            title="Previous"
            disabled={pagination.current <= 1}
            onClick={() => {
              setPagination({ ...pagination, current: pagination.current - 1 })
            }}
            className="text-pryBase flex gap-2"
          >
            <ArrowLeft className="w-5" />
            <span>Previous</span>
          </TextButton>
          {/* Next */}
          <TextButton
            title="Next"
            disabled={pagination.current === pagination.total}
            onClick={() => {
              setPagination({ ...pagination, current: pagination.current + 1 })
            }}
            className="text-pryBase flex gap-2"
          >
            <span>Next</span>
            <ArrowRight className="w-5" />
          </TextButton>
        </div>
      </div>
    </div>
  )
}
