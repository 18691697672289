import {
  Button,
  ContentWrapper,
  H4,
  IconButton,
  Loader,
  SchoolBrand,
} from "components"
import {
  ActivateProfileCredentialsInterface,
  Form,
  useAppNotificationStore,
  usePaymentStore,
  useProfileStore,
} from "stores"
import { useCallback, useEffect, useState } from "react"
import { formatMoney } from "utils"
import { ArrowLeft2, Check, CloseCircle } from "iconsax-react"
import { useNavigate } from "react-router-dom"

type TransactionType = {
  transactionRef: string
  paymentDescription: string
  paidBy: string
  transactionDate: string
  transactionAmount: number
  isSuccessful: boolean
}

const TableRow: React.FC<{
  index: number
  transaction: TransactionType
  handleVerifyPayment: Function
}> = ({ index, transaction, handleVerifyPayment }) => {
  return (
    <tr
      key={index}
      className={`hover:bg-pryLight1`}
    >
      <td
        align="center"
        className="align-middle text-base font-normal text-accentText py-[22px] px-2.5"
      >
        <span className={`text-base font-normal text-accentText`}>
          {index + 1}
        </span>
      </td>
      <td
        align="center"
        className="align-middle text-base font-normal text-accentText py-[22px] px-2.5"
      >
        <span className={`text-base font-normal text-accentText`}>
          {transaction.transactionRef}
        </span>
      </td>
      <td
        align="center"
        className="align-middle text-base font-normal text-accentText py-[22px] px-2.5"
      >
        <span className={`text-base font-normal text-accentText`}>
          {transaction.paymentDescription}
        </span>
      </td>
      <td
        align="center"
        className="align-middle text-base font-normal text-accentText py-[22px] px-2.5"
      >
        <span className={`text-base font-normal text-accentText`}>
          #{formatMoney(transaction.transactionAmount, 0)}
        </span>
      </td>
      <td
        align="center"
        className="align-middle text-base font-normal text-accentText py-[22px] px-2.5"
      >
        <span className={`text-base font-normal text-accentText`}>
          {transaction.transactionDate}
        </span>
      </td>
      <td
        align="center"
        className="align-middle text-base font-normal text-accentText py-[22px] px-2.5"
      >
        {transaction.isSuccessful ? (
          <Check className="text-stateSuccess" />
        ) : (
          <CloseCircle className="text-stateError" />
        )}
      </td>
      <td
        align="center"
        className="align-middle text-base font-normal text-accentText py-[22px] px-2.5"
      >
        <Button
          type="button"
          title="Verify"
          color="primary"
          onClick={() => handleVerifyPayment(transaction.transactionRef)}
        >
          Verify
        </Button>
      </td>
    </tr>
  )
}

export const Payments: React.FC<{}> = () => {
  const [form] = useState(new Form<ActivateProfileCredentialsInterface>())
  const { toast } = useAppNotificationStore()
  const { profile } = useProfileStore()
  const { getPayments, verifyPayment } = usePaymentStore()
  const [transactions, setTransactions] = useState<TransactionType[]>([])
  const navigate = useNavigate()

  const handleGetPayments = useCallback(async () => {
    try {
      form.loading(true)
      await getPayments().then((resp) => {
        form.loading(false)
        if (resp.isSuccessful === true) {
          setTransactions(resp.data)
        } else {
          toast.error(resp.message)
        }
      })
    } catch (error) {
      form.loading(false)
      console.log(error)
    }
  }, [form, getPayments, toast])

  const handleVerifyPayment = async (
    ref: TransactionType["transactionRef"]
  ) => {
    try {
      await verifyPayment({ transactionReference: ref }).then((resp) => {
        console.log(resp)
        if (resp.isSuccessful === true) {
          toast.success(resp.message)
          navigate("/auth/login")
        } else {
          toast.error(resp.message)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetPayments()
  }, [handleGetPayments])

  return (
    <ContentWrapper>
      <div className="h-full lg:w-8/12 w-[90%] mx-auto flex flex-col justify-start pt-5 items-center gap-10">
        {/* Welcome */}
        <div className="flex flex-col gap-10">
          <div className="flex gap-3 items-center">
            <IconButton
              title="Go back"
              className="mb-1"
              onClick={() => {
                navigate(-1)
              }}
            >
              <ArrowLeft2 className="text-pryBase" />
            </IconButton>
            <SchoolBrand className="text-pryBase mb-3" />
          </div>
          <div className="space-y-4">
            <H4 className="text-accentText font-semibold">All Payments</H4>
            <p className="text-accentGrey1">
              Hi <b>{profile?.firstname}</b>, Welcome to LAUTECH, this is a
              summary of all the transactions you performed on our modular
              payment system, incase any of the transaction was not successful
              and money has been deducted from your account, please do not
              hesitate to click on the refresh button.
              <br />
              <br />
              However, if the problem persists endeavour to contact any of our
              support staff.
            </p>
          </div>
        </div>

        <div>
          <Loader
            loading={form.isLoading}
            message="Loading transactions..."
            children={
              <div className="w-full overflow-x-auto">
                <table className="h-auto w-full table table-auto">
                  <thead className="table-header-group bg-pryLight1 text-accentText">
                    <tr>
                      <th
                        align="center"
                        className="align-middle px-2.5 font-medium text-base py-4 min-w-[60px]"
                      >
                        S/N
                      </th>
                      <th
                        align="center"
                        className="align-middle px-2.5 font-medium text-base py-4 min-w-[112px]"
                      >
                        Transaction&nbsp;Reference
                      </th>
                      <th
                        align="center"
                        className="align-middle px-2.5 font-medium text-base py-4 min-w-[232px]"
                      >
                        Payment&nbsp;Description
                      </th>
                      <th
                        align="center"
                        className="align-middle px-2.5 font-medium text-base py-4 min-w-[144px]"
                      >
                        Transaction&nbsp;Amount
                      </th>
                      <th
                        align="center"
                        className="align-middle px-2.5 font-medium text-base py-4 min-w-[168px]"
                      >
                        Transaction&nbsp;Date
                      </th>
                      <th
                        align="center"
                        className="align-middle px-2.5 font-medium text-base py-4 min-w-[96px]"
                      >
                        Status
                      </th>
                      <th
                        align="center"
                        className="align-middle px-2.5 font-medium text-base py-4 min-w-[96px]"
                      ></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {transactions.map((transaction, index) => (
                      <>
                        {index === 0 && (
                          <tr>
                            <td
                              colSpan={7}
                              className="h-2.5"
                            ></td>
                          </tr>
                        )}
                        <TableRow
                          key={index}
                          index={index}
                          transaction={transaction}
                          handleVerifyPayment={handleVerifyPayment}
                        />
                        {transactions.length - 1 > index && (
                          <tr>
                            <td colSpan={7}>
                              <hr className="mx-auto my-1 border-accentGrey3 w-full" />
                            </td>
                          </tr>
                        )}
                        {transactions.length - 1 === index && (
                          <tr>
                            <td
                              colSpan={7}
                              className="h-5"
                            ></td>
                          </tr>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            }
          />
        </div>
      </div>
    </ContentWrapper>
  )
}
