import {
  Button,
  CheckboxInput,
  H3,
  H4,
  Input,
  Label,
  LinkButton,
  PasswordInput,
  TextLink,
  TwoSidedContentWrapper,
} from "components"
import { images } from "assets"
import { useNavigate } from "react-router-dom"
import { useAppNotificationStore, useAuthStore, useForm } from "stores"
import { FormEvent } from "react"

export const ForgotPassword: React.FC<{}> = () => {
  const navigate = useNavigate()
  const {
    credentials,
    setCredential,
    errors,
    setError,
    submitting,
    isSubmitting,
  } = useForm()
  const { initiatePasswordReset } = useAuthStore()
  const { toast } = useAppNotificationStore()

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault()

    try {
      // if (credentials.password !== credentials.cpassword) {
      //   toast.error("Validation error.")
      //   setError("cpassword", [
      //     "The Password and Confirm Password must be the same!",
      //   ])
      //   return
      // }

      submitting(true)
      await initiatePasswordReset(credentials).then((resp) => {
        submitting(false)
        if (resp.isSuccessful === true) {
          toast.success(resp.message)
          navigate("/dashboard")
        } else {
          toast.error(resp.message)
        }
      })
    } catch (error) {
      submitting(false)
      console.log(error)
    }
  }

  return (
    <TwoSidedContentWrapper displayImage={images.OnboardingImageDark}>
      <div className="flex flex-col gap-10">
        {/* Welcome */}
        <div className="flex flex-col gap-14">
          <div className="space-y-4 text-center">
            <H3 className="text-accentText font-semibold">Admission Portal</H3>
            <p className="text-accentGrey1">
              Welcome to LAUTECH Admission portal. Register to start an
              application and login if you already created an account.
            </p>
          </div>
          <div className="space-y-4">
            <H4 className="text-accentText font-semibold">Forgot Password</H4>
            <p className="text-accentGrey1">
              Please enter your details to intiate password reset.
            </p>
          </div>
        </div>

        {/* Navigate to Registration */}
        <LinkButton
          to={"/auth/login"}
          title="Go to Login"
          className="bg-pryLight1 text-pryBase font-medium"
        >
          Click here to Login to your account
        </LinkButton>

        {/* Registration form */}
        <form
          onSubmit={handleLogin}
          className="w-full flex flex-col gap-8 items-start"
        >
          <div className="w-full flex flex-col gap-6 items-start">
            <div className="w-full">
              <Label
                htmlFor="username"
                aria-required={true}
              >
                Username
              </Label>
              <Input
                id="username"
                required
                autoComplete="off"
                className="w-full"
                placeholder="Enter your Username"
                disabled={isSubmitting}
                onChange={(e) => setCredential("username", e.target.value)}
              />
            </div>
          </div>
          <div className="w-full">
            <Button
              type="submit"
              // onClick={() => }
              title="Initate password reset"
              disabled={isSubmitting}
              submitting={isSubmitting ? "true" : "false"}
              className="w-full bg-pryBase text-white"
            >
              Initate password reset
            </Button>
          </div>
        </form>
      </div>
    </TwoSidedContentWrapper>
  )
}
