import { FormEvent, useEffect, useState } from "react"
import {
  Form,
  NextOfKinCredentialsInterface,
  useAppNotificationStore,
  useProfileStore,
} from "stores"
import {
  Button,
  H4,
  Input,
  Label,
  P1,
  SelectInput,
  SelectOption,
} from "components"
import { useNavigate } from "react-router-dom"

export const NextOfKin: React.FC<{}> = () => {
  const { setDashboardContext } = useAppNotificationStore()
  const navigate = useNavigate()
  const { profile, processNextOfKin } = useProfileStore()
  const { toast } = useAppNotificationStore()
  const [form] = useState(new Form<NextOfKinCredentialsInterface>())

  useEffect(() => {
    setDashboardContext("Next of Kin")
  }, [setDashboardContext])

  useEffect(() => {
    form.setCredentials({
      lastName: "",
      firstName: "",
      othername: "",
      email: profile?.nextEmail,
      mobileNumber: profile?.nextPhoneNumber,
      gender: profile?.nextGender,
      occupation: profile?.nextOccupation,
      relationship: "",
      address: profile?.nextAddress,
      lga: "",
    })
  }, [
    form,
    profile?.nextAddress,
    profile?.nextEmail,
    profile?.nextGender,
    profile?.nextOccupation,
    profile?.nextPhoneNumber,
  ])

  const handleSubmitNextOfKin = async (e: FormEvent) => {
    e.preventDefault()

    try {
      form.submitting(true)
      console.log(form.credentials)
      await processNextOfKin(
        form.credentials as NextOfKinCredentialsInterface
      ).then((resp) => {
        form.submitting(false)
        if (resp.isSuccessful === true) {
          toast.success(resp.message)
        } else {
          toast.error(resp.title)
          form.setErrors(resp.errors)
        }
      })
    } catch (error: any) {
      form.submitting(false)
      console.log(error)
      toast.error(error?.message as string)
    }
  }

  return (
    <div className="flex flex-col gap-12 items-center">
      <div className="flex flex-col items-center gap-6">
        <H4 className="font-semibold text-accentText">Next of Kin</H4>
        <P1 className="text-accentGrey1 text-center">
          Please Enter your Next of Kin details
        </P1>
      </div>
      <form
        onSubmit={handleSubmitNextOfKin}
        className="w-full flex flex-col gap-8"
      >
        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="flex gap-4 w-full">
            <div className="space-y-[5px] w-1/4 h-full">
              <Label aria-required>Title</Label>
              <SelectInput required>
                {["Mr", "Mrs", "Miss", "Other"].map((option, index) => (
                  <SelectOption
                    key={index}
                    value={option.toLowerCase()}
                  >
                    {option}
                  </SelectOption>
                ))}
              </SelectInput>
            </div>
            <div className="space-y-[5px] w-full">
              <Label aria-required>First Name</Label>
              <Input
                placeholder="Enter Next of kin first name"
                required
                disabled={form.isSubmitting}
                defaultValue={form.credentials?.firstName}
                onChange={(e) =>
                  form.setCredential("firstName", e.target.value)
                }
              />
            </div>
          </div>
          <div className="space-y-[5px] w-full">
            <Label aria-required>Surname</Label>
            <Input
              placeholder="Enter Next of kin surname"
              required
              disabled={form.isSubmitting}
              defaultValue={form.credentials?.lastName}
              onChange={(e) => form.setCredential("lastName", e.target.value)}
            />
          </div>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="space-y-[5px] w-full">
            <Label aria-required>Other Name(s)</Label>
            <Input
              placeholder="Enter Next of kin other name(s)"
              required
              disabled={form.isSubmitting}
              defaultValue={form.credentials?.othername}
              onChange={(e) => form.setCredential("othername", e.target.value)}
            />
          </div>
          <div className="space-y-[5px] w-full">
            <Label aria-required>Email address</Label>
            <Input
              placeholder="Enter Next of kin email address"
              required
              disabled={form.isSubmitting}
              defaultValue={form.credentials?.email}
              onChange={(e) => form.setCredential("email", e.target.value)}
            />
          </div>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="space-y-[5px] w-full">
            <Label aria-required>Phone Number</Label>
            <Input
              type={"tel"}
              placeholder="Enter Next of kin phone number"
              required
              disabled={form.isSubmitting}
              defaultValue={form.credentials?.mobileNumber}
              onChange={(e) =>
                form.setCredential("mobileNumber", e.target.value)
              }
            />
          </div>
          <div className="space-y-[5px] w-full">
            <Label aria-required>Select Gender</Label>
            <SelectInput
              placeholder="Select gender of Next of kin"
              required
              disabled={form.isSubmitting}
              defaultValue={form.credentials?.gender}
              onChange={(e) => form.setCredential("gender", e.target.value)}
            >
              <SelectOption value="male">Male</SelectOption>
              <SelectOption value="female">Female</SelectOption>
            </SelectInput>
          </div>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="space-y-[5px] w-full">
            <Label aria-required>Occupation</Label>
            <Input
              placeholder="Enter Next of kin Occupation"
              required
              disabled={form.isSubmitting}
              defaultValue={form.credentials?.occupation}
              onChange={(e) => form.setCredential("occupation", e.target.value)}
            />
          </div>
          <div className="space-y-[5px] w-full">
            <Label aria-required>Select Relationship</Label>
            <SelectInput
              placeholder="Select relationship with Next of kin"
              required
              disabled={form.isSubmitting}
              defaultValue={form.credentials?.relationship}
              onChange={(e) =>
                form.setCredential("relationship", e.target.value)
              }
            >
              {[
                "Sibling",
                "Spouse",
                "Husband",
                "Wife",
                "Brother",
                "Sister",
                "Aunty",
                "Uncle",
                "Father",
                "Mother",
                "Other",
              ].map((option, index) => (
                <SelectOption
                  key={index}
                  value={option.toLowerCase()}
                >
                  {option}
                </SelectOption>
              ))}
            </SelectInput>
          </div>
        </div>

        <div className="space-y-[5px] w-full">
          <Label aria-required>Address</Label>
          <Input
            placeholder="Enter Next of kin address"
            required
            disabled={form.isSubmitting}
            defaultValue={form.credentials?.address}
            onChange={(e) => form.setCredential("address", e.target.value)}
          />
        </div>

        <div className="flex gap-4 justify-end items-center mt-2">
          <Button
            type="button"
            title="Previous"
            color="secondary"
            className="w-52"
            onClick={() => navigate("/dashboard/registration/bio-data")}
          >
            Previous
          </Button>
          <Button
            type="submit"
            title="Next"
            color="primary"
            className="w-52"
            disabled={form.isSubmitting}
            submitting={form.isSubmitting ? "true" : "false"}
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  )
}
