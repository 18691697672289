import { useEffect } from "react"
import { stageLevel, useAppNotificationStore, useProfileStore } from "stores"
import { illustrations } from "assets"
import { H4, H5, P1, P2, WelcomeNote } from "components"
import { Link } from "react-router-dom"

type DashboardComponentType = {
  title: string
  link: string
  description: string
  illustration: string
  completed: boolean
}

export const Dashboard: React.FC<{}> = () => {
  const { setDashboardContext } = useAppNotificationStore()
  const { profile } = useProfileStore()

  useEffect(() => {
    setDashboardContext("Dashboard")
  }, [setDashboardContext])

  const components: DashboardComponentType[] = [
    {
      title: "Bio-Data",
      link: "registration/bio-data",
      description: "Bio-Data Complete your Personal Information Not Completed",
      illustration: illustrations.BioData,
      completed: stageLevel[profile?.currentStage!] > stageLevel.Biodata,
    },
    {
      title: "Next of Kin",
      link: "registration/next-of-kin",
      description: "Complete the information about your next of kin",
      illustration: illustrations.Guardian,
      completed: stageLevel[profile?.currentStage!] > stageLevel.NextOfKin,
    },
    {
      title: "Program Choices",
      link: "registration/program-choices",
      description: "Select your program and field of study",
      illustration: illustrations.ProgramChoices,
      completed:
        stageLevel[profile?.currentStage!] > stageLevel.ProgrammeApplication,
    },
    {
      title: "Records",
      link: "registration/records",
      description:
        "Upload your Results, certificates and other proof of record.",
      illustration: illustrations.Records,
      completed:
        stageLevel[profile?.currentStage!] > stageLevel.EmploymentRecord,
    },
  ]

  return (
    <div className="flex flex-col gap-16">
      <WelcomeNote />

      <div className="flex flex-col gap-8">
        <H5 className="font-medium">Complete your Application</H5>
        <div className="flex flex-col lg:flex-row gap-6 flex-wrap mx-auto justify-center">
          {components.map((component, index) => (
            <Link
              to={component.link}
              key={index}
              className="rounded-lg border cursor-pointer border-accentGrey2 w-full lg:w-[calc(50%-12px)] px-6 py-8 flex flex-col lg:flex-row justify-between items-start lg:items-center gap-4"
            >
              <div className="flex flex-col items-start lg:items-center lg:flex-row gap-4">
                <img
                  src={component.illustration}
                  alt={component.title}
                  className="h-[48px] lg:h-auto w-auto"
                />
                <div className="h-full flex flex-col gap-3">
                  <H4 className="text-accentText font-semibold">
                    {component.title}
                  </H4>
                  <P1 className="text-accentGrey1">{component.description}</P1>
                </div>
              </div>
              <hr className="w-full block lg:hidden border-t border-accentGrey3 " />
              <div className="flex flex-col gap-2 justify-end">
                {component.completed === true ? (
                  <>
                    <div className="hidden lg:flex rounded-full border border-pryBase bg-primary p-0 w-6 h-6 self-end justify-center items-center">
                      <img
                        src={illustrations.Check}
                        alt="Check"
                      />
                    </div>
                    <P2 className="text-pryBase">Completed</P2>
                  </>
                ) : (
                  <>
                    <div className="hidden lg:block rounded-full border border-accentGrey2 w-6 h-6 self-end"></div>
                    <P2 className="text-accentGrey2">
                      <span className="inline-block lg:hidden">
                        Status:&nbsp;
                      </span>
                      <span>Not&nbsp;Completed</span>
                    </P2>
                  </>
                )}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}
