import { useEffect, useState } from "react"
import { useAppNotificationStore } from "stores"
import {
  Button,
  H4,
  H6,
  Label,
  P1,
  P2,
  SelectInput,
  SelectOption,
  TextButton,
} from "components"
import { Image, Maximize4, Trash } from "iconsax-react"
import { images } from "assets"

type UploadedResultType = {
  type: string
  file: File
}

export const Records: React.FC<{}> = () => {
  const { setDashboardContext } = useAppNotificationStore()

  useEffect(() => {
    setDashboardContext("Results")
  }, [setDashboardContext])

  const [uploadedResults, updateUploadedResults] = useState<
    UploadedResultType[]
  >([
    { type: "WAEC Result", file: new File([], "") },
    { type: "Birth Certificate", file: new File([], "") },
  ])

  return (
    <div className="flex flex-col gap-12 items-center">
      <div className="flex flex-col items-center gap-6">
        <H4 className="font-semibold text-accentText">Records</H4>
        <P1 className="text-accentGrey1 text-center">
          Upload your Results, certificates and other proof of record.
        </P1>
      </div>
      <form className="w-full lg:w-[536px] flex flex-col gap-8">
        <div className="space-y-[5px] w-full">
          <Label aria-required>Select Result or Certificate Type</Label>
          <SelectInput required>
            {["WAEC Result", "NECO Result", "GCE Result"].map(
              (option, index) => (
                <SelectOption
                  key={index}
                  value={option.toLowerCase().replace(/\s/g, "-")}
                >
                  {option}
                </SelectOption>
              )
            )}
          </SelectInput>
        </div>

        <div className="space-y-[5px] w-full">
          <Label aria-required>Result Image</Label>
          <div className="w-full h-40 border border-dashed border-accentIcon2 rounded-xl flex flex-col justify-center items-center gap-4 p-10 lg:p-8">
            <Image className="text-pryBase rounded-md border-2 border-pryBase min-w-[36px] min-h-[36px] w-9 h-9 p-0" />
            <P2 className="text-accentText font-medium text-center">
              Drop your image here, or{" "}
              <label
                htmlFor="browse"
                className="text-pryBase cursor-pointer"
              >
                browse
              </label>{" "}
              <input
                id="browse"
                type="file"
                className="hidden"
              />{" "}
              <br />
              <span className="text-accentGrey2">
                JPG,PNG and JPEG files are allowed(max file size of 4MB)
              </span>
            </P2>
          </div>
        </div>

        <H6 className="text-accentText font-semibold">Uploaded Results</H6>

        <div className="flex flex-col gap-0.5">
          {uploadedResults.length === 0 ? (
            <P1 className="text-accentGrey1">No uploaded results yet.</P1>
          ) : (
            uploadedResults.map((result, index) => (
              <>
                <div
                  key={index}
                  className="w-full flex justify-between items-center"
                >
                  <div className="flex items-center gap-10">
                    <img
                      src={images.Document}
                      alt={result.type}
                      className="rounded-[4px] w-10 h-10"
                    />
                    <P1 className="text-accentText font-semibold">
                      {result.type}
                    </P1>
                  </div>
                  <div className="flex items-center gap-6">
                    <TextButton
                      type="button"
                      title="View"
                      className="flex gap-3 items-center"
                    >
                      <Maximize4
                        className="text-accentText"
                        size={18}
                      />
                      <P1 className="hidden lg:block text-accentGrey1 font-medium">
                        View
                      </P1>
                    </TextButton>
                    <TextButton
                      type="button"
                      title="View"
                      className="flex gap-3 items-center text-stateError"
                    >
                      <Trash size={18} />
                      <span className="hidden lg:block font-medium">
                        Delete
                      </span>
                    </TextButton>
                  </div>
                </div>
                {index < uploadedResults.length - 1 && (
                  <hr className="my-0.5 text-accentGrey3" />
                )}
              </>
            ))
          )}
        </div>

        <div className="flex gap-4 justify-end items-center mt-2">
          <Button
            type="submit"
            title="Previous"
            color="secondary"
            className="w-52"
          >
            Previous
          </Button>
          <Button
            type="submit"
            title="Next"
            color="primary"
            className="w-52"
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  )
}
