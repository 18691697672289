/**
 * An optimized label.
 *
 * @param props
 * @returns label
 */
export const Label: React.FC<React.LabelHTMLAttributes<HTMLLabelElement>> = (
  props
) => {
  return (
    <label
      {...props}
      className={`text-accentText mb-1 text-sm font-medium leading-5 ${props.className}`}
    >
      {props.children}
      {
        props["aria-required"] !== undefined
        && <span className="text-pryBase">*</span>
      }
    </label>
  )
}
