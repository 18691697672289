/**
 * An optimized input.
 *
 * @param props
 * @returns input
 */
export const SelectOption: React.FC<
  React.OptionHTMLAttributes<HTMLOptionElement>
> = (props) => {
  return (
    <option
      {...props}
      className={`text-accentText ${
        props.selected ? "text-pryBase font-semibold" : ""
      } ${props.className}`}
    />
  )
}
