import { Button, H3, H4, LinkButton, TwoSidedContentWrapper } from "components"
import { images } from "assets"
import {
  ActivateProfileCredentialsInterface,
  Form,
  useAppNotificationStore,
  usePaymentStore,
  useProfileStore,
} from "stores"
import { useState } from "react"

export const InitiatePayment: React.FC<{}> = () => {
  const [form] = useState(new Form<ActivateProfileCredentialsInterface>())
  const { toast } = useAppNotificationStore()
  const { profile } = useProfileStore()
  const { initiatePayment } = usePaymentStore()

  const handleInitiatePayment = async () => {
    try {
      form.submitting(true)
      await initiatePayment({ payType: "ApplicationFee" }).then((resp) => {
        if (resp.isSuccessful === true) {
          toast.success(resp.message)
          window.location.href = resp.data.paymentUrl
        } else {
          toast.error(resp.message)
        }
      })
    } catch (error) {
      form.submitting(false)
      console.log(error)
    }
  }

  return (
    <TwoSidedContentWrapper displayImage={images.OnboardingImageDark}>
      <div className="flex flex-col gap-10">
        {/* Welcome */}
        <div className="flex flex-col gap-14">
          <div className="space-y-4 text-center">
            <H3 className="text-accentText font-semibold">Admission Portal</H3>
            <p className="text-accentGrey1">
              Welcome to LAUTECH Admission portal. Register to start an
              application and login if you already created an account.
            </p>
          </div>
          <div className="space-y-4">
            <H4 className="text-accentText font-semibold">Payments</H4>
            <p className="text-accentGrey1">
              Hi <b>{profile?.firstname}</b>, Welcome to LAUTECH Open and
              Distance Learning Centre. You are seeing this page because we
              discovered that you have not paid your application fee. Kindly
              click the proceed button to pay the fee. Alternatively, you can
              re-validate your payment in case your money has been debited and
              you are still seeing this page.
            </p>
          </div>
        </div>

        {/* Navigate to Login */}
        <div className="w-full flex flex-col gap-3">
          <LinkButton
            title="Verify Payments"
            className="bg-pryLight1 text-pryBase font-medium"
            to={"all"}
          >
            Verify Payments
          </LinkButton>
          <Button
            title="Pay Application Fee"
            className="bg-pryBase text-pryLight1 font-medium"
            disabled={form.isSubmitting}
            submitting={form.isSubmitting ? "true" : "false"}
            onClick={handleInitiatePayment}
          >
            Pay Application Fee
          </Button>
        </div>
      </div>
    </TwoSidedContentWrapper>
  )
}
