import { ArrowDown2 } from "iconsax-react"
import { useState } from "react"
import { SelectOption } from "./SelectOption"

/**
 * An optimized input.
 *
 * @param props
 * @returns input
 */
export const SelectInput: React.FC<
  React.SelectHTMLAttributes<HTMLSelectElement>
> = (props) => {
  const [selectedPlaceholder, toggleSelectPlaceholder] = useState(
    typeof props.placeholder !== "undefined"
  )
  return (
    <div className="relative h-full w-full">
      <select
        {...props}
        onChange={(e) => {
          toggleSelectPlaceholder(e.target.value === "__placeholder__")
          if (e.target.value !== "__placeholder__")
            props.onChange && props.onChange!(e)
        }}
        className={`rounded-lg w-full border border-accentGrey3 bg-white py-3.5 px-3 ${
          selectedPlaceholder === true ? "text-accentGrey2" : "text-accentText"
        } focus:outline-0 ${props.className}`}
      >
        {props.placeholder && (
          <SelectOption
            className="text-accentGrey2"
            value="__placeholder__"
          >
            {props.placeholder}
          </SelectOption>
        )}
        {props.children}
      </select>
      {/* <span onClick={(e) => {}} className="absolute rounded-lg top-0 right-0.5 bg-white my-0.5 h-[calc(100%-4px)] flex items-center pl-0 pr-3 text-accentText">
        <ArrowDown2
          size={16}
          className="text-accentText"
        />
      </span> */}
    </div>
  )
}
