import React from "react"
import { NotificationBar } from "./NotificationBar"
import { MobileSideNavigationBar, SideNavigationBar } from "./SideNavigationBar"
import { Outlet } from "react-router-dom"
import { DashboardContent } from "./DashboardContent"
import { useAppNotificationStore } from "../../stores"
// import { useAppNotificationStore, useAuthStore } from "../../stores"
// import { throttle } from "../../utils"

export const DashboardWrapper: React.FC<{}> = () => {
  // const { isAuthenticated } = useAuthStore()
  const { menuOpen } = useAppNotificationStore()
  // const navigate = useNavigate()

  // useEffect(() => {
  //   return throttle(() => {
  //     if (isAuthenticated === false) {
  //       toast.error("Session timed out. Please login!")
  //       navigate("/auth/login")
  //     }
  //   })
  // })

  return (
    <div className="h-screen w-screen">
      <NotificationBar />
      {/* mx-auto max-w-screen-xl */}

      <div className="bg-white h-full w-full overflow-x-hidden">
        <div className="h-full w-full flex justify-start">
          <div className="hidden lg:block">
            <SideNavigationBar />
          </div>

          <div
            className={`${menuOpen === true ? "block" : "hidden"} lg:hidden`}
          >
            <MobileSideNavigationBar />
          </div>

          <DashboardContent>
            <Outlet />
          </DashboardContent>
        </div>
      </div>
    </div>
  )
}
