export const formatMoney = (amount: number, decimalCount = 2, decimal = ".", thousands = ",") => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(Math.abs(amount || 0).toFixed(decimalCount))
    let iStr = i.toString();
    let j = (iStr.length > 3) ? iStr.length % 3 : 0;

    return negativeSign + (j ? iStr.substr(0, j) + thousands : '') + iStr.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e: any) {
    console.log(e.message)
  }
}