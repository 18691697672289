import React from "react"
import { NotificationBar } from "./NotificationBar"

type props = {
  children: JSX.Element | JSX.Element[]
}

export const ContentWrapper: React.FC<props> = ({ children }) => {
  return (
    <div className="h-screen w-full">
      <NotificationBar />
      {children}
    </div>
  )
}
