/**
 * An optimized link button.
 * Pass the `title` props, else `⚠` is returned
 */
export const AnchorButton: React.FC<
  React.AnchorHTMLAttributes<HTMLAnchorElement>
> = (props) => {
  if (!props["title"]) return <span children={"⚠"} />
  return (
    <a
      aria-label={props.title}
      {...props}
      className={`text-center rounded-lg hover:opacity-95 active:opacity-95 focus:opacity-95 disabled:opacity-50 py-3.5 px-4 lg:px-12 font-semibold focus:outline-none focus:shadow-none ${props.className}`}
    >
      {props.children}
    </a>
  )
}
